/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment, useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import api from "../services/api";
import { userUtils } from "../utils/user";

import { SystemNotification } from "@kentarepo/kcf-templates";
import { Header } from "../features/Header";
import logo from "../assets/Kenta_4.0_Judiciário.png";
import {
  getValueFromlocalStorage,
  insertTolocalStorage,
} from "../utils/sessionStorageEncrypt";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCustomerProfile,
  setMenuListAction,
  setProfileNameAction,
} from "../store/modules/profile/actions";
import {
  getAllNotifications,
  readAllNotifications,
  readNotification,
} from "./constants";
import {
  IGetAllPermissionByCustomerIdAndProfileId,
  ISystemNotificationrBody,
  permissionServices,
} from "@kentarepo/kcf-services";
import { LoadingComponent } from "@kentarepo/kcf-assets";
import { LoadingContainer } from "../pages/app/audiences/styles";
import { updateUser } from "../store/modules/user/actions";
import { setPermissions } from "../store/modules/permission/actions";
import { IMenuList } from "./interface";
import { menusListDefault } from "../utils/menus";
import ContainerGlobalDownload from "../features/ContainerGlobalUpload";

import useWebsocket from "react-use-websocket";
import { useToast } from "../hooks/toast";
import { DownloadAppModal } from "../features/Header/DownloadAppModal";
import { LogOperation } from "../utils/entities/logOperation";
import { FORMS_HEARING } from "../utils/entities/permission";
import { usePermissions } from "../hooks/permission";

type Props = {
  children: JSX.Element;
  role?: string;
};

export function ProtectedRoute({ children }: Props) {
  const navigate = useNavigate();
  const {
    userCustomerProfile,
    profileName: reduxProfileName,
    menus,
  } = useSelector((state: any) => state.profile);

  const { export: exportDownloads } = usePermissions(FORMS_HEARING.HEARING_FILE);
  const { globalConfigurations } = useSelector(
    (state: any) => state.configurations
  );  

  //================================================================================================================================ States
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openDownloadAppModal, setOpenDownloadAppModal] =
    useState<boolean>(false);
  const [menuIndexToShow, setMenuIndexToShow] = useState<number>(-1);
  const [changeAll, setChangeAll] = useState<boolean>(false);
  const [notificationId, setNotificationId] = useState<number>(0);
  const [qntNotifications, setQntNotifications] = useState<number>(0);
  const [listOfNotifications, setListOfNotifications] = useState<
    Array<ISystemNotificationrBody>
  >([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [logoConfiguration, setLogoConfiguration] = useState("");

  const user = getValueFromlocalStorage("@Kenta:user");
  const profile = getValueFromlocalStorage("@Kenta:profile");

  //================================================================================================================================ Redux

  const dispatch = useDispatch();
  const location = useLocation();

  //================================================================================================================================ UseEffect

  async function handleGetPermissions({
    customerId,
    profileId,
  }: IGetAllPermissionByCustomerIdAndProfileId) {
    const apiPermissionservice = permissionServices(api);

    const { data, success } =
      await apiPermissionservice.getAllPermissionByCustomerIdAndProfileId({
        customerId,
        profileId,
      });

    if (success && data && data?.length) {
      insertTolocalStorage("@Kenta:permissions", JSON.stringify(data ?? []));
      dispatch(setPermissions(data ?? []));
      return data ?? [];
    }
  }

  useEffect(() => {
    if (changeAll) {
      handleRealAllNotifications();
    }
  }, [changeAll]);

  useEffect(() => {
    if (notificationId !== 0) {
      handleReadNotification();
    }
  }, [notificationId]);

  useEffect(() => {
    getNotifications();

    if (user) {
      const parseUser = JSON.parse(user);
      dispatch(updateUser(parseUser));
    }
    if (profile && !userCustomerProfile) {
      const parsedProfile = JSON.parse(profile);

      dispatch(selectCustomerProfile(parsedProfile));

      if (parsedProfile && parsedProfile.profileName) {
        dispatch(setProfileNameAction(parsedProfile?.profileName));
        const filteredMenus: Array<IMenuList> = menusListDefault.filter((x) =>
          x.rolesToShow.includes(parsedProfile.profileName)
        );

        dispatch(setMenuListAction(filteredMenus));
      }
    }
  }, []);

  useEffect(() => {
    if (location?.pathname === "/dashboard") {
      return setMenuIndexToShow(-1);
    }
    if (profile && userCustomerProfile) {
      const parsedProfile = JSON.parse(profile);

      if (parsedProfile && parsedProfile.profileName) {
        const filteredMenus: Array<IMenuList> = menusListDefault.filter((x) =>
          x.rolesToShow.includes(parsedProfile.profileName)
        );

        const findMenuIndex = filteredMenus.findIndex((x: any) => {
          return location.pathname.includes(x.location);
        });
        if (findMenuIndex !== -1 && location?.pathname !== "/dashboard") {
          setMenuIndexToShow(findMenuIndex);
        }
      }
    }
  }, [location?.pathname, userCustomerProfile]);

  //================================================================================================================================ SINAL DE VIDA

  const { addToast } = useToast();

  const WS_URL_LIFE_SIGNAL = `${
    import.meta.env.VITE_APP_WSS_BASE_URL
  }/activeAccess`;

  const [lifeSignal, setLifeSignal] = useState("True");

  const { sendMessage: sendMessageLifeSinal } = useWebsocket(
    WS_URL_LIFE_SIGNAL,
    {
      onMessage: (event: MessageEvent<any>) => {
        if (event.data !== "-1") setLifeSignal(event.data);
      },
      onOpen: () => {},
      onClose: () => {},
      onError: () => {},
      shouldReconnect: () => true,
      reconnectInterval: 1000,
    }
  );

  useEffect(() => {
    if (lifeSignal === "False") {
      addToast({
        type: "warning",
        title: "Acesso finalizado",
        description: "Sua sessão foi encerrada, por favor conecte-se novamente",
      });
      localStorage.clear();
    }
  }, [lifeSignal]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      (async () => {
        if (user || user) {
          const userPassed = user
            ? JSON.parse(user)
            : user
            ? JSON.parse(user)
            : "";
          sendMessageLifeSinal(userPassed.hash);
        }
      })();
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  //================================================================================================================================ NOTIFICATIONS WEBSOCKET
  const WS_URL_NOTIFICATION = `${
    import.meta.env.VITE_APP_WSS_BASE_URL
  }/notifications`;
  const { sendMessage: sendMessageNotification } = useWebsocket(
    WS_URL_NOTIFICATION,
    {
      onMessage: (event: MessageEvent<any>) => {
        if (event.data !== "-1") setQntNotifications(Number(event.data));
      },
      onOpen: () => {},
      onClose: () => {},
      shouldReconnect: () => true,
      reconnectInterval: 1000,
    }
  );

  useEffect(() => {
    if (LogOperation.getUserId()) {
      sendMessageNotification(String(LogOperation.getUserId()));
    }
  }, []);

  useEffect(() => {
    const intervalIdNotification = setInterval(() => {
      (async () => {
        if (user) {
          const userPassed = user ? JSON.parse(user) : "";
          sendMessageNotification(userPassed?.id);
        }
      })();
    }, 20000);

    return () => clearInterval(intervalIdNotification);
  }, []);

  //================================================================================================================================

  const changeProfile = async (newProfile: any) => {
    dispatch(selectCustomerProfile(newProfile));
    insertTolocalStorage("@Kenta:profile", JSON.stringify(newProfile));

    if (newProfile && newProfile.profileName) {
      if (user) {
        const parseUser = JSON.parse(user);
        dispatch(updateUser(parseUser));
        const data = await handleGetPermissions({
          profileId: newProfile?.profileId,
          customerId: parseUser?.customerId,
        });

        const menuList: Array<string> = [];
        if (data && data.length) {
          data.forEach((element: any) => {
            if (
              !menuList.includes(
                element.menu
                  ?.normalize("NFD")
                  ?.replace(/[^a-zA-Z\s]/g, "")
                  ?.toLowerCase()
              )
            ) {
              menuList.push(
                element.menu
                  ?.normalize("NFD")
                  ?.replace(/[^a-zA-Z\s]/g, "")
                  ?.toLowerCase()
              );
            }
          });

          insertTolocalStorage("@Kenta:menusList", JSON.stringify(menuList));
          const filteredMenus: Array<IMenuList> = menusListDefault.filter(
            (x) =>
              x.rolesToShow.includes(newProfile.profileName) &&
              menuList.includes(
                x.label
                  ?.normalize("NFD")
                  ?.replace(/[^a-zA-Z\s]/g, "")
                  ?.toLowerCase()
              )
          );

          dispatch(setMenuListAction(filteredMenus));

          navigate(filteredMenus[0].route);
          setMenuIndexToShow(0);
        }
      }

      dispatch(setProfileNameAction(newProfile.profileName));
    }
  };

  useEffect(() => {
    if (globalConfigurations && globalConfigurations.length) {
      globalConfigurations.forEach((element: any) => {
        if (element && element.name === "LogoCustomer") {
          setLogoConfiguration(element.value);
        }
      });
    }
  }, [globalConfigurations]);

  async function getNotifications() {
    const list = await getAllNotifications(api, userUtils.getUserId() ?? 0);
    setListOfNotifications(list);
    setQntNotifications(list?.length);
  }

  const onClickNotifications = async () => {
    await getNotifications();
    setOpenDrawer(true);
  };

  const handleReadNotification = async () => {
    const response = await readNotification(api, notificationId);
    if (response.status === 200) {
      const filteredList = listOfNotifications.filter(
        (item) => item?.id !== notificationId
      );
      setListOfNotifications(filteredList);
    }
    return false;
  };

  const handleRealAllNotifications = async () => {
    setLoading(true);
    const response = await readAllNotifications(api, listOfNotifications);

    if (response.status === 200) {
      setListOfNotifications([]);
      setChangeAll(false);
      setQntNotifications(0);
    }
    setLoading(false);
  };

  if (children && !user) {
    return <Navigate to="/" />;
  }

  return (
    <Fragment>
      <Header
        logo={logoConfiguration || logo}
        icons={[]}
        menuIndexToShow={menuIndexToShow}
        setMenuIndexToShow={setMenuIndexToShow}
        menus={menus}
        avatar={<Avatar src="https://mui.com/static/images/avatar/1.jpg" />}
        api={api}
        customerId={userUtils.getCustomerIdByUser() ?? 0}
        selectedProfile={userCustomerProfile}
        profileName={reduxProfileName}
        setSelectedProfile={changeProfile}
        indexHeaderMenu={0}
        onClickNotifications={onClickNotifications}
        onClickDownloadApp={() => setOpenDownloadAppModal(true)}
        qntNotifications={qntNotifications}
      />
      <SystemNotification
        title="Notificações"
        emptyMessage="Nenhuma notificação"
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
        labelReadAll="Marcar todas como lidas"
        changeAll={changeAll}
        setChangeAll={setChangeAll}
        listNotifications={listOfNotifications}
        setNotificationId={setNotificationId}
      />
      {children}
      {loading ? (
        <LoadingContainer>
          <LoadingComponent loading={loading} />
        </LoadingContainer>
      ) : null}
      {openDownloadAppModal && exportDownloads ? (
        <DownloadAppModal
          isOpenDownloadsModal={openDownloadAppModal}
          setIsOpenDownloadsModal={() =>
            setOpenDownloadAppModal(!openDownloadAppModal)
          }
        />
      ) : null}
      <ContainerGlobalDownload />
    </Fragment>
  );
}
