import { useState, useEffect } from "react";
import { AdminArea } from "../../../components/Body/AdminArea";

import { LogOperation } from "../../../utils/entities/logOperation";

//Toast
import { useDispatch } from "react-redux";
import { useToast } from "../../../hooks/toast";
import { hideLoading, showLoading } from "../../../store/modules/login/actions";

//Permissões
import { FORMS_ADMIN } from "../../../utils/entities/permission";

import { CreateEditProcedureCustomerAttendantRole } from "./CreateEdit";
import { hearingTypeAttendantRole } from "../../../services/hearingTypeAttendantRole";
import { ContainerAdmin } from "./styles";
import { ContainerLeftMenu } from "../Administration/styles";
import { Administration } from "../Administration";
import { DrawerDefault } from "@kentarepo/kcf-assets";

export interface IHearingTypeAttendantRoleItem {
  hearingTypeId: number,
  hearingTypeName: string,
  active: boolean,
  attendantRoleList: Array<IDefaultObject>
}

export interface IDefaultObject {
  hearingTypeAttendantRoleId?: number;
  id: number;
  name: string;
  active: boolean;
  attendantRoleId: number
  hearingTypeId: number
}
export interface IHearingTypeAttendantRoleResponseAPI {
  customerId: number;
  hearingType: Array<IDefaultObject>;
  attendantRole: Array<IDefaultObject>;
  hearingTypeAttendantRole: Array<IHearingTypeAttendantRoleItem>
}

export const HearingTypeAttendantRole = () => {

  const [hearingTypeList, setHearingTypeList] = useState<Array<IDefaultObject>>(
    []
  );
  const [attendantRoleList, setAttendantRoleList] = useState<
    Array<IDefaultObject>
  >([]);

  const [search, setSearch] = useState("");
  const [situation, setSituation] = useState<any>(undefined);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [element, setElement] = useState<any>();
  const [postOrPutTrue, setPostOrPutTrue] = useState<boolean>(false);
  const [hearingTypeId, setHearingTypeId] = useState<any>();

  const filteredData = hearingTypeList?.filter((item: any) =>
    item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  );

  const dispatch = useDispatch();
  const { addToast } = useToast();
  const customerId = LogOperation.getCustomerId();

  useEffect(() => {
      onLoadList();
  }, [search, situation]);

  async function onLoadList() {
    dispatch(showLoading());
    const response = await hearingTypeAttendantRole.getAll(
      customerId,
      situation
    );

    if (response) {
      if (response.status) {
        setHearingTypeList(response.data.data);
      } else {
        addToast({
          type: "warning",
          title: response.message,
        });
      }
    }
    dispatch(hideLoading());
    return [];
  }

  function clear() {
    setIsEdit(false);
    setElement(undefined);
    setOpenModal(false);
  }

  async function onClickHearingType(item: IDefaultObject) {
    setIsEdit(true);

    const returnConsult = await transformeHearingTypeAttendantRoleObject(item);

    setElement(returnConsult);
    setOpenModal(true);
  }

  async function transformeHearingTypeAttendantRoleObject(
    HearingType: IDefaultObject
  ) {
    setHearingTypeId(HearingType?.id)

    const consult = await hearingTypeAttendantRole.getAllRelationsProcedureCustomerTypeAttendantRole(
      LogOperation.getCustomerId(),
      HearingType?.id
    );



    const attendantRoleList = getAttendantRoleByConsult(consult.data.data);

    setAttendantRoleList(attendantRoleList);

    const returnConsult: IHearingTypeAttendantRoleItem = {
      hearingTypeId: HearingType.hearingTypeId,
      hearingTypeName: HearingType?.name,
      active: HearingType.active,
      attendantRoleList: getRelationshipHearingTypeWithAttendantRole(
        consult.data.data
      ),
    };

    return returnConsult;
  }

  function getAttendantRoleByConsult(consult: Array<any>) {
    let listAttentandRole: Array<IDefaultObject> = [];

    consult.forEach((element) => {
      listAttentandRole.push({
        id: element.attendantRoleId,
        name: element.attendantRoleName,
        active: element.active,
        hearingTypeAttendantRoleId: element?.id,
        attendantRoleId: element.attendantRoleId,
        hearingTypeId: element.hearingTypeId
      });
    });

    return listAttentandRole;
  }

  function getRelationshipHearingTypeWithAttendantRole(consult: Array<any>) {
    let listHearingTypeAttentandRole: Array<IDefaultObject> = [];
    consult.forEach((element) => {
      if (element.active === "TRUE") {
        listHearingTypeAttentandRole.push({
          id: element.attendantRoleId,
          name: element.attendantRoleName,
          active: element.active,
          hearingTypeAttendantRoleId: element?.id,
          attendantRoleId: element.attendantRoleId,
          hearingTypeId: element.hearingTypeId
        });
      }
    });

    return listHearingTypeAttentandRole;
  }

  return (
    <ContainerAdmin>
      <ContainerLeftMenu>
        <Administration />
      </ContainerLeftMenu>
      <AdminArea
        title={"Vincular tipo de audiência e participante"}
        InputSearchLabel="Buscar por:"
        InputSearchOnChange={(text) => setSearch(text)}
        list={filteredData}
        onClick={(item: any) => onClickHearingType(item)}
        setItemReturnClick={setElement}
        setOpenModal={setOpenModal}
        setIsEdit={setIsEdit}
        permissionName={FORMS_ADMIN.HEARING_TYPE_ATTENDANT_ROLE}
        onChangeSituation={(value: any) => setSituation(value)}
        showStatus={true}
        displayAtivoFilter={true}
        searchText={search}
      />
      <DrawerDefault
        openDrawer={openModal}
        setOpenDrawer={() => clear()}
        onClose={() => clear()}
        title={
          isEdit
            ? "Vínculo tipo audiência e tipo participante"
            : "Criar vinculo tipo participante tipo oitiva"
        }
        onApply={() => setPostOrPutTrue(true)}
        children={
          <CreateEditProcedureCustomerAttendantRole
            isEdit={isEdit}
            hearingTypeId={hearingTypeId}
            postOrPutTrue={postOrPutTrue}
            setPostOrPutTrue={setPostOrPutTrue}
            closeModal={openModal}
            setCloseModal={setOpenModal}
            hearingTypeList={hearingTypeList}
            attendantRoleList={attendantRoleList}
            hearingTypeAttendantRoleItem={element}
          />
        }
      />
    </ContainerAdmin>
  );
};
