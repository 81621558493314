import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
  `;

//Body
export const ContainerBody = styled.div`
  width: 100% - 48px;
  overflow: hidden;
  padding: 0 24px;
  padding-bottom: 20px;
`;

//List
export const ContainerList = styled.div`
  width: 100%;
  height: 64vh;
  padding-top: 16px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;

  @media screen and (min-height: 850px) {
    height: 70vh;
  }
`;