/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from "react";
import { IRightTemplate } from "./interface";
import { formatDateTime, hearingFileService } from "@kentarepo/kcf-services";
import { MdImage, MdVideoLibrary, MdAudioFile } from "react-icons/md";
import FileIcon from "../../assets/svg/FileIcon.svg";
import DownloadIcon from "../../assets/svg/download-c.svg";
import DashIcon from "../../assets/svg/check_drop.svg";
import Notificar from "../../assets/svg/Notificar.svg";
import { ItemDetailLine } from "../../components/ItemDetailLine";
import { convertType } from "../../utils/format";
import { Tooltip } from "react-tooltip";
import fileIcon from "../../assets/hearingDetails/file.svg";
import edit from "../../assets/hearingDetails/edit_attachment.svg";
import IconDelete from "../../assets/svg/IconDelete.svg";
import { useDispatch, useSelector } from "react-redux";
import api from "../../services/api";
import { LogOperation } from "../../utils/entities/logOperation";
import { useToast } from "../../hooks/toast";
import { usePermissions } from "../../hooks/permission";
import { FORMS_ADMIN, FORMS_HEARING } from "../../utils/entities/permission";
import { insertTolocalStorage } from "../../utils/sessionStorageEncrypt";
import {
  closeModalAttachmentFile,
  editAttachmentFile,
  setAttachmentList,
} from "../../store/modules/attachment/actions";

import {
  handleCopyMeetingLink,
  listAttendants,
  listItemSchadule,
} from "./constants";

import {
  BaseLabel,
  TitleSubTitleLabel,
  MenuListGeneral,
  colors,
  CircularButton,
  ModalDefault,
} from "@kentarepo/kcf-assets";

import {
  AttachmentContainer,
  AttachmentIconContainer,
  Container,
  ContainerInfos,
  ContainerText,
  DateItem,
  DatesContainer,
  Description,
  FirstTexts,
  Left,
  LeftContainer,
  Right,
  RightContainer,
  Spacer,
  Title,
  TitleContainer,
} from "./styles";

export const RightTemplate: React.FC<IRightTemplate> = ({
  hearingId,
  hearingNumber,
  startDate,
  origin,
  data,
  navigate,
  navigateTo,
  disabled,
  listAttendant,
  onClickRecoding,
  onClick,
  onClickDetach,
  addButton,
  onClickDownloads,
  addNewAttachment,
  onClickDelete,
  onClickValidation,
  onClickEdit,
  setListAttendant,
  onClickNotification,
  listRecord,
  audience,
  record,
}) => {
  const [listSchadule, setListSchadule] = useState<Array<any>>([]);
  const [deleteId, setDeleteId] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { attachmentList } = useSelector((store: any) => store.attachment);

  const dispatch = useDispatch();
  const hearingFileServiceApi = hearingFileService(api);
  const { addToast } = useToast();

  //#region Permissions
  const { read, edit, hashValidator, notify } = usePermissions(
    FORMS_HEARING.HEARING
  );

  const { create: createAttendant, exclude: excludeAttendant } = usePermissions(
    FORMS_ADMIN.ATTENDANT
  );

  const {
    edit: editHearingFile,
    exclude: excludeHearingFile,
    export: exportFiles,
    import: importFiles,
  } = usePermissions(FORMS_HEARING.HEARING_FILE);

  //#endregion

  const {
    create: hearingFileCreate,
    edit: hearingFileEdit,
    exclude: hearingFileExclude,
  } = usePermissions(FORMS_HEARING.HEARING_FILE);

  useEffect(() => {
    if (data) {
      setListAttendant(data.hearingAttendants);
      setListSchadule(data.hearingScheduled);
    }
  }, [data]);

  useEffect(() => {
    (async () => {
      const hearingFileServiceApi = hearingFileService(api);
      const { status, data: dataAttach } =
        await hearingFileServiceApi.getAllDownloadsByHearingId(hearingId);

      dispatch(setAttachmentList([]));
      if (status === 200) {
        const newList = dataAttach?.data?.filter((a) => a.type !== 1 && a.type !== 6);

        dispatch(setAttachmentList(newList));
      }
    })();
  }, [hearingId]);

  const showFidedignidade = listRecord?.some((item: any) => item.status === 13);

  const onClickAta = () => {
    if (audience && record) {
      insertTolocalStorage("choosePage", JSON.stringify(3));

      navigate(
        `/audiences/fullview?audienceNumber=${audience.procedureNumber}&hearingFileId=${record?.id}&hearingId=${audience?.id}&audienceVideo=${record.filename}`,
        { state: audience }
      );
    }
  };

  const listLines = [
    {
      id: "2",
      icon: FileIcon,
      label: "Ata",
      disabled: disabled,
      onClick: record ? onClickAta : () => {},
      permission: true,
      hr: false,
    },
    {
      id: "3",
      icon: DownloadIcon,
      label: "Downloads",
      disabled: disabled,
      onClick: exportFiles ? () => onClickDownloads() : () => {},
      permission: Boolean(exportFiles),
      hr: true,
    },
    {
      id: "4",
      icon: DashIcon,
      label: "Validação de fidedignidade de video",
      disabled: disabled,
      onClick: hashValidator ? onClickValidation : () => {},
      permission: Boolean(hashValidator) && Boolean(showFidedignidade),
      hr: true,
    },
    {
      id: "5",
      icon: Notificar,
      label: "Notificar",
      disabled: disabled,
      onClick: notify ? onClickNotification : () => {},
      permission: Boolean(notify),
      hr: true,
    },
  ];

  const handleClick = () => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  const handleDeleteAttachment = async () => {
    if (deleteId) {
      try {
        setShowDeleteModal(false);
        const { status } = await hearingFileServiceApi.deleteById(deleteId);

        if (status === 200 && deleteId) {
          dispatch(closeModalAttachmentFile(true));
          const filterAtachment = attachmentList.filter(
            (x: any) => x.hearingFileId !== deleteId
          );

          dispatch(setAttachmentList(filterAtachment));
          await LogOperation.postLogOperation("HearingFile", 3, deleteId);
          addToast({
            type: "success",
            title: "Anexo",
            description: "Seu anexo foi excluído com sucesso",
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <>
      {read ? (
        <Container disabled={disabled} data-testid={hearingId}>
          <ContainerInfos>
            <TitleContainer>
              <Left>
                <BaseLabel
                  disabled={disabled}
                  fontWeight="700"
                  fontSize="18px"
                  marginLeft="0px"
                >
                  <p
                    style={{ wordBreak: "break-word", paddingTop: "10px" }}
                  >{`Audiência nº ${hearingNumber}`}</p>
                </BaseLabel>
              </Left>
              <Right>
                <CircularButton
                  disabled={!edit || disabled}
                  icon="edit"
                  onClick={edit ? handleClick : () => {}}
                />
              </Right>
            </TitleContainer>
            <FirstTexts>
              <TitleSubTitleLabel
                childrenTitle="Origem"
                fontSizeTitle="12px"
                fontWeightTitle="400"
                colorTitle={colors.neutral_gray_40}
                childrenSubTitle={origin}
                fontSizeSubTitle="12px"
                fontWeightSubTitle="700"
                colorSubTitle={colors.neutral_black}
                marginBetweenTitleAndSubTitle="0px"
                marginLeft="0px"
              />
              <DatesContainer>
                <DateItem>
                  <TitleSubTitleLabel
                    childrenTitle="Data audiência"
                    fontSizeTitle="12px"
                    fontWeightTitle="400"
                    colorTitle={colors.neutral_gray_40}
                    childrenSubTitle={formatDateTime.getFullDate(startDate)}
                    fontSizeSubTitle="12px"
                    fontWeightSubTitle="700"
                    colorSubTitle={colors.neutral_black}
                    marginBetweenTitleAndSubTitle="0px"
                    marginLeft="0px"
                  />
                </DateItem>
                <DateItem>
                  <TitleSubTitleLabel
                    childrenTitle="Hora"
                    fontSizeTitle="12px"
                    fontWeightTitle="400"
                    colorTitle={colors.neutral_gray_40}
                    childrenSubTitle={`${formatDateTime.getHour(
                      startDate
                    )}:${formatDateTime.getMinutes(startDate)}`}
                    fontSizeSubTitle="12px"
                    fontWeightSubTitle="700"
                    colorSubTitle={colors.neutral_black}
                    marginBetweenTitleAndSubTitle="0px"
                    marginLeft="0px"
                  />
                </DateItem>
              </DatesContainer>
            </FirstTexts>
          </ContainerInfos>
          <Spacer />
          <MenuListGeneral
            disabled={disabled}
            title="Audiências agendadas"
            handleButtonClick={() => {}}
            showButton={false}
            list={listItemSchadule({
              listSchadule,
              navigate,
              navigateTo,
              onClickCopy: (e) => handleCopyMeetingLink(e, addToast),
              onClickDelete:
                onClickDelete && hearingFileExclude ? onClickDelete : () => {},
              onClickEdit:
                onClickEdit && hearingFileEdit ? onClickEdit : () => {},
              onClickRecoding: hearingFileCreate ? onClickRecoding : () => {},
              disabledDelete: hearingFileExclude,
              disabledEdit: hearingFileEdit,
              disabledRecording: hearingFileCreate,
            })}
            handleOpen={() => "Audiências agendadas"}
            emptyMessage="Nenhuma audiência agendada"
          />
          <Spacer />
          <MenuListGeneral
            disabled={disabled}
            title="Participantes"
            handleButtonClick={createAttendant ? addButton : () => {}}
            showButton={true}
            list={listAttendants(
              listAttendant,
              onClickDetach,
              excludeAttendant
            )}
            handleOpen={() => "Participantes"}
            emptyMessage="Nenhum participante encontrado"
          />
          <Spacer />
          <MenuListGeneral
            disabled={disabled}
            title="Anexos"
            handleButtonClick={importFiles ? addNewAttachment : () => {}}
            showButton={Boolean(importFiles)}
            list={AttachmentItemList(
              attachmentList,
              setShowDeleteModal,
              setDeleteId,
              editHearingFile,
              excludeHearingFile
            )}
            handleOpen={() => {}}
            emptyMessage="Nenhum anexo encontrado"
          />
          <Spacer />
          {listLines.map((line) => (
            <ItemDetailLine
              key={line?.id}
              disabled={line.disabled}
              onClick={line.onClick as any}
              icon={<img src={line.icon} alt={line.label} />}
              label={line.label}
              permissions={line.permission}
            />
          ))}
          <ModalDefault
            openModal={showDeleteModal}
            setOpenModal={() => setShowDeleteModal(false)}
            title={"Excluir anexo"}
            bodyMessageTitle={"Deseja excluir anexo?"}
            bodyMessageSubTitle={"Esta ação não poderá ser desfeita"}
            textConfirm={"EXCLUIR"}
            onClickConfirm={() => handleDeleteAttachment()}
            textCancel={"CANCELAR"}
            onClickCancel={() => setShowDeleteModal(false)}
          />
        </Container>
      ) : null}
    </>
  );
};

const imageType = [
  {
    name: "Foto",
    icon: <MdImage color="#7887A9" />,
  },
  {
    name: "Vídeo",
    icon: <MdVideoLibrary color="#7887A9" />,
  },
  {
    name: "Áudio",
    icon: <MdAudioFile color="#7887A9" />,
  },
];

const AttachmentItemList = (
  attachmentList: any[],
  setShowDeleteModal: any,
  setDeleteId: any,
  editHearingFile: boolean | undefined,
  excludeHearingFile: boolean | undefined
): any[] => {
  const dispatch = useDispatch();
  function formatFileSize(bytes: number) {
    if (bytes < 1024) {
      return bytes + " bytes";
    } else if (bytes < 1024 * 1024) {
      return (bytes / 1024).toFixed(2) + " KB";
    } else if (bytes < 1024 * 1024 * 1024) {
      return (bytes / (1024 * 1024)).toFixed(2) + " MB";
    } else if (bytes < 1024 * 1024 * 1024 * 1024) {
      return (bytes / (1024 * 1024 * 1024)).toFixed(2) + " GB";
    } else {
      return (bytes / (1024 * 1024 * 1024 * 1024)).toFixed(2) + " TB";
    }
  }

  const handleEditFile = (editFile: any) => {
    dispatch(editAttachmentFile(editFile));
  };

  return attachmentList.map((item: any, index: number) => {
    return (
      <Fragment>
        <AttachmentContainer key={index}>
          <LeftContainer>
            <AttachmentIconContainer>
              {convertType(item?.type) === "Documento" ? (
                <img src={fileIcon} alt="" />
              ) : (
                imageType.find((x) => x?.name === convertType(item?.type))?.icon
              )}
            </AttachmentIconContainer>
            <ContainerText>
              <Title>
                {item.filename.length >= 22
                  ? `${item.filename.substring(0, 22)}...`
                  : item.filename}
              </Title>
              <Description>{`${convertType(item?.type)}, ${formatFileSize(
                item.size
              )}`}</Description>
            </ContainerText>
          </LeftContainer>
          <RightContainer className="container_buttons">
            {excludeHearingFile ? (
              <button
                onClick={() => {
                  setDeleteId(item.hearingFileId);
                  setShowDeleteModal(true);
                }}
                id="delete"
              >
                <img style={{ width: 18 }} src={IconDelete} alt="" />
              </button>
            ) : null}
            {editHearingFile ? (
              <button onClick={() => handleEditFile(item)} id="edit">
                <img src={edit} alt="" />
              </button>
            ) : null}
            <a target="_blank" href={item.url} rel="noreferrer" download />
          </RightContainer>
          <Tooltip anchorSelect="#delete" place="top" content="Excluir" />
          <Tooltip anchorSelect="#edit" place="top" content="Editar" />
          <Tooltip anchorSelect="#download" place="top" content="Download" />
        </AttachmentContainer>
      </Fragment>
    );
  });
};
