import React, { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { usePermissions } from "../../../hooks/permission";
import { FORMS_ADMIN } from "../../../utils/entities/permission";
import { IListItem } from "../../../features/menus/Left/interface";
import { LeftMenu } from "../../../features/menus/Left";

// eslint-disable-next-line prefer-const
let listMenusOpen = ["cadastros", "configurações"];

export const Administration: React.FC = () => {
  const currentLocale = useLocation();
  const navigate = useNavigate();
  
  
  useEffect(() => {
    if (currentLocale.pathname === "/administration") {
      navigate("/administration/Attendant");
    }
  }, []);

  //cad
  const { read: attendantRead } = usePermissions(FORMS_ADMIN.ATTENDANT);
  const { read: hearingTypeRead } = usePermissions(FORMS_ADMIN.HEARING_TYPE);
  const { read: PlaceTypeRead } = usePermissions(FORMS_ADMIN.PLACE);
  const { read: attendantRoleRead } = usePermissions(
    FORMS_ADMIN.ATTENDANT_ROLE
  );
  const { read: profileRead } = usePermissions(FORMS_ADMIN.PROFILE);
  const { read: systemConfigurationRead } = usePermissions(FORMS_ADMIN.SYSTEM_CONFIGURATION);

  //config
  const { read: certificateRead } = usePermissions(FORMS_ADMIN.CERTIFICATE);
  const { read: userRead } = usePermissions(FORMS_ADMIN.USER_CUSTOMER);
  const { read: hearingTypeattendant } = usePermissions(FORMS_ADMIN.HEARING_TYPE_ATTENDANT_ROLE);
  const { read: serviceIntegrationImportation } = usePermissions(FORMS_ADMIN.SERVICE_INTEGRATION_IMPORTATION);
  const { read: CustomerTemplate } = usePermissions(FORMS_ADMIN.CUSTOMER_TEMPLATE);

  const list: Array<IListItem> = [
    {
      title: "Cadastros",      
      navigation: [
        {
          id: "participantes",
          menuTitle: "Participantes",
          locale: "/administration/Attendant",
          read: attendantRead,
          datacy: "participantes",
        },
        {
          id: "tipos_de_audiências",
          menuTitle: "Tipos de audiências",
          locale: "/administration/hearingType",
          read: hearingTypeRead,
          datacy: "tipos_de_audiências",
        },
        {
          id: "tipos_de_participantes",
          menuTitle: "Tipos de participantes",
          locale: "/administration/attendantRole",
          read: attendantRoleRead,
          datacy: "tipos_de_participantes",
        },
        {
          id: "tipos_de_unidades",
          menuTitle: "Unidades",
          locale: "/administration/Place",
          read: PlaceTypeRead,
          datacy: "tipos_de_unidades",
        },
        {
          id: "tipo_de_audiencia_papel_do_participante",
          menuTitle: "Tipo de audiência  - Papel do participante",
          locale: "/administration/hearingTypeAttendantRole",
          read: hearingTypeattendant,
          datacy: "tipos_de_audiencia_papel_do_participante"
        },
        {
          id: "notificações_de_integração",
          menuTitle: "Configuração de importação de dados",
          locale: `/administration/ServiceIntegrationImportation`,
          read: serviceIntegrationImportation,
          datacy: "notificações_de_integração"
        },
        {
          id: "customer_template",
          menuTitle: "Templates",
          locale: `/administration/CustomerTemplate`,
          read: CustomerTemplate,
          datacy: "customer_template"
        }
      ],
    },
    {
      title: "Configurações",
      navigation: [
        {
          id: "certificado",
          menuTitle: "Certificado",
          locale: "/administration/CustomerCertificate",
          read: certificateRead,
          datacy: "certificado",
        },
        {
          id: "usuarios",
          menuTitle: "Usuários",
          locale: "/administration/Users",
          read: userRead,
          datacy: "usuarios",
        },
        {
          id: "perfis",
          menuTitle: "Perfis",
          locale: "/administration/UserCustomerProfiles",
          read: profileRead,
          datacy: "perfis",
        },
        {
          id: "Configurações do sistema",
          menuTitle: "Configurações do sistema",
          locale: "/administration/SystemConfiguration",
          read: systemConfigurationRead,
          datacy: "configuracoes_sistema"
        },
      ],
    },
  ];

  return (
    <LeftMenu
      currentLocale={currentLocale.pathname}
      type="administration"
      menuTitle="Administração"
      data-cy="administracao"
      menuList={list}
      backgroundHeader="primary"
      navigate={navigate}
      listMenusOpen={listMenusOpen}
    />
  );
};
