/* eslint-disable @typescript-eslint/no-explicit-any */
import { BaseBadge, CircularButton, GeneralInput, Switch } from "@kentarepo/kcf-assets"
import { ContainerFormOneLabel, ContainerFormOneLabelSwitch, ContainerTitleWithNumber, ListUnitArea, Title, UnitArea } from "./styles"

import { CardAdminItem } from "../../../../components/CardAdminItem";
import { useEffect } from "react";
import { AttendantRoleService } from "../../../../services/attendantRole";
import { useDispatch } from "react-redux";
import { SetUserCreateDistrict } from "../../../../store/modules/userCreateUnit/actions";

export interface IProps {
  userId: number | undefined;
  name: string;
  email: string;
  onChangeEmail: (e: any) => unknown;
  onChangeName: (e: any) => unknown;
  isActive: boolean;
  onChangeSwitch: (e: boolean) => unknown;
  userCreateDistrictList: any;
  openModalVincule: () => unknown;
  errorName: boolean;
  errorEmail: boolean;
  onChangeExtraContent: (e: any) => unknown;
  extraContent: any;
  isEdit: boolean;
  onClick: (item: any) => void;
  items?: any;
  onClickDelete?: (props: any) => void;
  isEditCreated: boolean
}


export const CreateUserComponent = ({
  userId,
  name,
  email,
  onClick,
  onChangeEmail,
  extraContent,
  onChangeName,
  onChangeSwitch,
  isActive,
  userCreateDistrictList,
  openModalVincule,
  errorName,
  errorEmail,
  isEdit,
  isEditCreated
}: IProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (extraContent) {
      onChangeEmail(extraContent.email);
      onChangeName(extraContent.name);

    }
  }, []);


  async function getDataUnit() {
    if (userId) {
      const { data } = await AttendantRoleService.getAllByUser(userId);
      dispatch(
        SetUserCreateDistrict({
          userData: data?.data,
          errorName: !name,
          errorEmail: !email,
        })
      );
    }
  }


  useEffect(() => {
    getDataUnit();
  }, [userId]);


  function compared(a: any, b: any) {
    if (a.trigger < b.trigger) return -1;
    if (a.trigger > b.trigger) return 1;
    return 0;
  }

  return (
    <ContainerFormOneLabel>
      <ContainerFormOneLabelSwitch>
        <Switch
          checked={isActive}
          onChange={() => onChangeSwitch(!isActive)}
          label="Ativo"
        />
      </ContainerFormOneLabelSwitch>
      <GeneralInput
        id="name"
        label="Nome"
        name="formOneLabel"
        onChange={(e) => onChangeName(e)}
        value={name}
        error={errorName}
        required
        marginRight="0px"
        maxLength={90}
      />
      <div style={{ marginTop: "16px" }}>
        <GeneralInput
          id="email"
          label="Email"
          name="formOneLabel"
          onChange={(e) => onChangeEmail(e)}
          value={email}
          error={errorEmail}
          required
          disabled={isEdit || isEditCreated}
          marginRight="0px"
          maxLength={90}
        />
      </div>

      <UnitArea>
        <ContainerTitleWithNumber>
          <Title style={{ marginRight: 10 }}>Unidades vinculadas
            <BaseBadge color="primary" content={
              userCreateDistrictList?.userData?.length > 0
                ? userCreateDistrictList?.userData?.filter((e: any) => e.active || e.itemType).length
                : 0
            } />
          </Title>
          <CircularButton id="add_icon" icon="add" onClick={openModalVincule} />
        </ContainerTitleWithNumber>
      </UnitArea>
      <ListUnitArea>
        {
          userCreateDistrictList?.userData?.sort(compared)?.map((item: any) =>
            <CardAdminItem
              key={item.id}
              isActive={isActive}
              id={item?.id}
              name={!item?.id && item.type === 0 ? item.courtLabel : item.place}
              description={item?.profile}
              active={item?.active}
              onClickEditAction={() => onClick(item)}
              disable={false}
            />
          )}
      </ListUnitArea>
    </ContainerFormOneLabel>
  )
}