/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { ReportMenu } from "../ReportMenu";
import { ContainerLeftMenu } from "../../Administration/styles";
import { Container, ContainerBody, ContainerList } from "./styles";
import { ReportsService } from "../../../../services/reportsService";
import { useToast } from "../../../../hooks/toast";
import { LoadSpinner } from "../../../../features/LoadSpinner";
import { ReactPaginateKenta } from "@kentarepo/kcf-templates";
import { Box } from "@mui/material";
import { userUtils } from "../../../../utils/user";
import {
  handleChangeFinishDate,
  handleChangeStartDate,
  handleListFormat,
} from "./constants";
import {
  Button,
  DateInput,
  IWord,
  PageHeader,
  ReportCard,
  SearchEmpty,
} from "@kentarepo/kcf-assets";
import { usePermissions } from "../../../../hooks/permission";
import { FORMS_RELATORIOS } from "../../../../utils/entities/permission";

export const TranscriptionReport: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<string | null>(
    new Date().toString()
  );
  const [finishDate, setFinishDate] = useState<string | null>(
    new Date().toString()
  );
  const [startDateError, setStartDateError] = useState<boolean>(false);
  const [finishDateError, setFinishDateError] = useState<boolean>(false);
  const [list, setList] = useState<Array<any>>([]);

  // eslint-disable-next-line prefer-const
  let startDateErrorFlag: boolean = false;
  // eslint-disable-next-line prefer-const
  let finishDateErrorFlag: boolean = false;
  const { addToast } = useToast();

  const { read } = usePermissions(FORMS_RELATORIOS.TRASCRIPTION);

  const reportTitle: IWord[] = [
    { label: "Duracao Min.", width: "11%", date: false },
    { label: "Inicio", width: "10.5%", date: false },
    { label: "Fim", width: "11.5%", date: false },
    { label: "Processamento Seg.", width: "18%", date: false },
    { label: "Tipo", width: "17.5%", date: false },
    { label: "Produto", width: "19%", date: false },
    { label: "Tentativas", width: "11%", date: false },
  ];

  const listComponent = list?.map((item: any, index: number) => (
    <div style={{ marginTop: "2px" }}>
      <ReportCard
        id={"report-card" + index}
        selected={false}
        height={100}
        disabled={true}
        words={item}
        fontSize={"12px"}
        title={false}
        children={<></>}
      />
    </div>
  ));

  const handleSearch = async () => {
    try {
      setLoading(true);

      if (
        !startDateErrorFlag &&
        startDate !== null &&
        !finishDateErrorFlag &&
        finishDate !== null
      ) {
        const startDateAux = new Date(startDate);
        const finishDateAux = new Date(finishDate);

        if (startDateAux > finishDateAux) {
          addToast({
            type: "warning",
            title: "Aviso",
            description: "Data início não pode ser maior que data fim",
          });
          return;
        }

        const customerId = userUtils.getCustomerIdByUser();

        if (customerId === null) {
          addToast({
            type: "error",
            title: "Aviso",
            description: "Cliente não encontrado",
          });
          return;
        }

        const listService = await ReportsService.getTranscriptionKentaAI(
          customerId,
          startDateAux,
          finishDateAux
        );
        const listAux = handleListFormat(listService.data);
        setList(listAux);
      } else {
        addToast({
          type: "warning",
          title: "Aviso",
          description: "Preencha as datas para consultar",
        });
        return;
      }
    } catch {
      addToast({
        type: "error",
        title: "Aviso",
        description: "Ocorreu um erro inesperado",
      });
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box style={{ display: "flex" }}>
      <ContainerLeftMenu>
        <ReportMenu />
      </ContainerLeftMenu>
      <Box style={{ width: "100%" }}>
        <PageHeader
          quantity={list.length > 0 ? list.length : 0}
          id="header"
          data-cy="header"
          label={"Relatório de transcrições"}
          background="secondary"
          addClick={undefined}
          disabled={false}
        />
        <ContainerBody>
        {read ? (
          <>
            <Container>
              <DateInput
                label={"Data início"}
                value={startDate}
                setValue={(e) =>
                  handleChangeStartDate(e, setStartDate, setStartDateError)
                }
                error={startDateError}
                required
              />
              <DateInput
                label={"Data fim"}
                value={finishDate}
                setValue={(e) =>
                  handleChangeFinishDate(e, setFinishDate, setFinishDateError)
                }
                error={finishDateError}
                required
              />
              <Button onClick={handleSearch} marginTop="26px">
                Pesquisar
              </Button>
            </Container>
            <ContainerList>
              {loading ? (
                <LoadSpinner loading={loading} />
              ) : (
                <>
                  {listComponent.length > 0 ? (
                    <ReportCard
                       id="report-card-1"
                        selected={false}
                        height={100}
                        disabled={true}
                        words={reportTitle}
                        fontSize={"14px"}
                        fontWeight="700"
                        title
                        children={<></>}
                        background="#D1DBE3"
                    />
                  ) : null}
                  <ReactPaginateKenta
                    list={listComponent}
                    title={"Relatório de transcrições"}
                    onChangePage={() => {}}
                    height="55vh"
                  />
                </>
              )}
            </ContainerList>
          </>
        ) : (
          <SearchEmpty
            textMessage={"Sem permissão para seu perfil"}
            typeIcon={"avatar"}
            marginTop="30px"
          />
        )}
        </ContainerBody>
      </Box>
    </Box>
  );
};
