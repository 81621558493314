import React, { useEffect, useState } from "react";
import { Container, HeaderContainer, Content, HeaderTitle } from "./styles";
import ArrowDown from "../../assets/svg/arrow-down.svg";
import ArrowUp from "../../assets/svg/arrow-up.svg";
import UploadFileItem from "../../components/UploadFileItem";
import { useDispatch, useSelector } from "react-redux";
import { IUpload } from "../../store/modules/attachment/interface";
import { cleanUpload } from "../../store/modules/attachment/actions";
import { useToast } from "../../hooks/toast";

const ContainerGlobalDownload: React.FC = () => {
  const [show, setShow] = useState(false);
  const [showContainer, setShowContainer] = useState(false);
  const { uploadList, uploadedList } = useSelector(
    (state: any) => state.attachment
  );
  const dispatch = useDispatch();
  const { addToast } = useToast();

  useEffect(() => {
    if (uploadList.length) {
      uploadList.forEach((element: IUpload) => {
        if (!element.finished) {
          setShowContainer(true);
          setShow(true);
        }
      });
    }

    if (!uploadList.length) {
      setShowContainer(false);
      setShow(false);
    }
  }, [uploadList.length]);

  useEffect(() => {
    if (uploadList.length && uploadedList.length) {
      if (uploadList.length === uploadedList.length) {
        setShow(false);
        setShowContainer(false);
        dispatch(cleanUpload());
        addToast({
          type: "success",
          title: "Uploads",
          description: "Todos os uploads foram finalizados",
        });
      }
    }
  }, [uploadedList.length, uploadList.length]);

  return showContainer ? (
    <Container>
      <HeaderContainer onClick={() => setShow(!show)}>
        <HeaderTitle>Enviando arquivos</HeaderTitle>
        <img src={show ? ArrowDown : ArrowUp} alt="" />
      </HeaderContainer>

      <Content show={show}>
        {uploadList.map((element: IUpload) => (
          <UploadFileItem item={element} isMain={element.isMainRecord} />
        ))}
      </Content>
    </Container>
  ) : null;
};

export default ContainerGlobalDownload;
