/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { DropdownGlobal, IDropDown } from "../../../components/DropdownGlobal";
import { SystemConfigurationService } from "../../../services/systemConfiguration/index";
import Resizer from "react-image-file-resizer";

//Toast
import { hideLoading, showLoading } from "../../../store/modules/login/actions";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "../../../hooks/toast";

//own styled
import { Container } from "./styles";
import { ShortCuts } from "./customConfigsComponents/shortcutsCustom/index";
import { InputGeneral } from "../../../components/Input";
import { getValueFromlocalStorage, insertTolocalStorage } from "../../../utils/sessionStorageEncrypt";

import {
  ContainerBody,
  ContainerInputs,
} from "../StyleCSS/bodyCreateEdit";
import { FORMS_ADMIN } from "../../../utils/entities/permission";
import { AdminArea } from "../../../components/Body/AdminArea";
import { updateGlobalConfigurationsAction } from "../../../store/modules/globalConfiguration/actions";
import { DrawerDefault } from "@kentarepo/kcf-assets";
import { ContainerLeftMenu } from "../Administration/styles";
import { Administration } from "../Administration";
import { listDaysAux, listFirstShortcut, listPagination, listProviderAux, listSecondShortcut, listSecondsToNextBack, listYesOrNoAux, editConfig } from "./constants";
import LoginConfigurationCustom from "./customConfigsComponents/LoginConfigurationCustom";
import LogoCustom from "./customConfigsComponents/LogoCustom";
import StorageProviderCustom from "./customConfigsComponents/StorageProviderCustom";
import { typesInput } from "./interface";
import WatermarkCustom from "./customConfigsComponents/WatermarkCustom";
import ServiceIntegrationAuthentication from "./customConfigsComponents/ServiceIntegrationAuthentication";
import ServiceIntegrationImportation from "./customConfigsComponents/ServiceIntegrationImportation";
import ServiceIntegrationNotification from "./customConfigsComponents/ServiceIntegrationNotification";
import ProcedureMask from "./customConfigsComponents/ProcedureMask";
import { LoadSpinner } from "../../../features/LoadSpinner";
import TranscriptionProvider from "./customConfigsComponents/TranscriptionProvider";

interface IShortcut {
  name: string;
  value: string;
}

export function SystemConfiguration() {
  const user = getValueFromlocalStorage("@Kenta:user");
  const [fieldError, setFieldError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { containerName } = useSelector((store: any) => store.profile);

  const dispatch = useDispatch();
  const { addToast } = useToast();

  let customerId: any = null;

  if (user) {
    const user_parsed = JSON.parse(user);
    customerId = user_parsed.customerId;
  }

  const [selectedYesOrNoName, setSelectedYesOrNoName] = useState<string>("");

  const [selectedDaysToGetProcedureName, setSelectedDaysToGetProcedureName] =
    useState<string>("");

  //SHORTCUTS
  //para o boby
  const [shortCutsErrorplayStop, setShortCutsErrorPlayStop] = useState(false);
  const [shortCutsErrorActionBackTime, setShortCutsErrorActionBackTime] =
    useState(false);
  const [shortCutsErrorActionNextTime, setShortCutsErrorActionNextTime] =
    useState(false);
  const [shortCutsErrorSlowSpeed, setShortCutsErrorSlowSpeed] = useState(false);
  const [shortCutsErrorFastSpeed, setShortCutsErrorFastSpeed] = useState(false);

  //-------------------------------------------------
  //para controle

  const [selectedListFirstShortcut, setSelectedListFirstShortcut] = useState<
    Array<IDropDown>
  >([]);

  const [selectedListSecondShortcut, setSelectedListSecondShortcut] = useState<
    Array<IDropDown>
  >([]);

  const [selectedListSecondsToNextBack, setSelectedListSecondsToNextBack] =
    useState<Array<IDropDown>>([]);

  const [valueTimeBackNextName, setTimeBackNextName] = useState<string>(
    listSecondsToNextBack[0]?.name
  );
  const [valueTimeBackNextId, setTimeBackNextId] = useState<number>(5);

  const [valuePlayStopFirstName, setPlayStopFirstName] = useState<string>(
    listFirstShortcut[0]?.name
  );
  const [valuePlayStopFirstId, setPlayStopFirstId] = useState<number>(0);

  const [valuePlayStopSecondName, setPlayStopSecondName] = useState<string>(
    listSecondShortcut[0]?.name
  );
  const [valuePlayStopSecondId, setPlayStopSecondId] = useState<number>(0);

  const [valueActionBackTimeFirstName, setActionBackTimeFirstName] =
    useState<string>(listFirstShortcut[0]?.name);
  const [valueActionBackTimeFirstId, setActionBackTimeFirstId] =
    useState<number>(0);

  const [valueActionBackTimeSecondName, setActionBackTimeSecondName] =
    useState<string>(listSecondShortcut[0]?.name);
  const [valueActionBackTimeSecondId, setActionBackTimeSecondId] =
    useState<number>(0);

  const [valueActionNextTimeFirstName, setActionNextTimeFirstName] = useState(
    listFirstShortcut[0]?.name
  );
  const [valueActionNextTimeFirstId, setActionNextTimeFirstId] =
    useState<number>(0);

  const [valueActionNextTimeSecondName, setActionNextTimeSecondName] =
    useState<string>(listSecondShortcut[0]?.name);
  const [valueActionNextTimeSecondId, setActionNextTimeSecondId] =
    useState<number>(0);

  const [valueSlowSpeedFirstName, setSlowSpeedFirstName] = useState<string>(
    listFirstShortcut[0]?.name
  );
  const [valueSlowSpeedFirstId, setSlowSpeedFirstId] = useState<number>(0);

  const [valueSlowSpeedSecondName, setSlowSpeedSecondName] = useState<string>(
    listSecondShortcut[0]?.name
  );
  const [valueSlowSpeedSecondId, setSlowSpeedSecondId] = useState<number>(0);

  const [valueFastSpeedFirstName, setFastSpeedFirstName] = useState<string>(
    listFirstShortcut[0]?.name
  );
  const [valueFastSpeedFirstId, setFastSpeedFirstId] = useState<number>(0);

  const [valueFastSpeedSecondName, setFastSpeedSecondName] = useState<string>(
    listSecondShortcut[0]?.name
  );
  const [valueFastSpeedSecondId, setFastSpeedSecondId] = useState<number>(0);

  const [withIndexing, setWithIndexing] = useState(false)

  //=====================================================================================

  const [selectedListPagination, setSelectedListPagination] = useState<
    Array<IDropDown>
  >([]);

  const [valuePaginationName, setPaginationName] = useState<string>(
    listPagination[0]?.name
  );

  async function onLoadList() {
    setLoading(true);
    const response = await SystemConfigurationService.getAllByCustomer(
      customerId
    );

    if (response) {
      if (response.status) {
        const listFilter = response.data.filter((x: any) => x.customerId !== 0);

        for (let i = 0; i < listFilter.length; i++) {
          if (listFilter[i]?.name === "DaysToGetProcedure") {
            if (listFilter[i].value === "30") {
              setSelectedDaysToGetProcedureName(listDaysAux[0]?.name);
            }
            if (listFilter[i].value === "60") {
              setSelectedDaysToGetProcedureName(listDaysAux[1]?.name);
            }
          }
          if (listFilter[i].name === "FacialRecognition") {
            if (listFilter[i].value === "true" || listFilter[i].value === "1") {
              setSelectedYesOrNoName(listYesOrNoAux[1]?.name);
            }
            if (
              listFilter[i].value === "false" ||
              listFilter[i].value === "0"
            ) {
              setSelectedYesOrNoName(listYesOrNoAux[0]?.name);
            }
          }
          if (listFilter[i]?.name === "PaginationList") {
            const paginationListAuxId: number = parseInt(
              listFilter[i].value as string
            );

            const paginationListAuxName = listPagination.find(
              (x) => x?.id === paginationListAuxId
            )?.name;

            setPaginationName(
              paginationListAuxName === undefined ? "5" : paginationListAuxName
            );
          }
          //ShortCuts
          if (listFilter[i]?.name === "Shortcuts") {
            const listShortcuts: Array<IShortcut> = JSON.parse(
              listFilter[i].value
            );
            if (listShortcuts.length > 0) {
              separateFirstAndSecond(listShortcuts);
            }
          }
          // Prazo
          if (listFilter[i]?.name === "CustomerProcedureDeadline") {
            if (listFilter[i].value === "0") {
              setDeadlineOff(true);
            } else {
              setDeadlineOff(false);
            }
          }
        }
      } else {
        addToast({
          type: "warning",
          title: response.message,
        });
      }
    }

    dispatch(hideLoading());
    setLoading(false);
    return [];
  }

  function validateFormFields(values: IShortcut[]) {
    setShortCutsErrorPlayStop(false);
    setShortCutsErrorActionBackTime(false);
    setShortCutsErrorActionNextTime(false);
    setShortCutsErrorSlowSpeed(false);
    setShortCutsErrorFastSpeed(false);

    values &&
      values.forEach((value: IShortcut) => {
        switch (value?.name) {
          case "playStop":
            setShortCutsErrorPlayStop(true);
            break;
          case "actionBackTime":
            setShortCutsErrorActionBackTime(true);
            break;
          case "actionNextTime":
            setShortCutsErrorActionNextTime(true);
            break;
          case "slowSpeed":
            setShortCutsErrorSlowSpeed(true);
            break;
          case "fastSpeed":
            setShortCutsErrorFastSpeed(true);
            break;
        }
      });
  }

  const findDuplicates = (array: IShortcut[]) => {
    const valueCount: { [key: string]: number } = {};
    const duplicates: IShortcut[] = [];

    array.forEach((item: IShortcut) => {
      if (valueCount[item.value]) {
        valueCount[item.value]++;
      } else {
        valueCount[item.value] = 1;
      }
    });

    array.forEach((item: IShortcut) => {
      if (valueCount[item.value] > 1) {
        duplicates.push(item);
      }
    });
    validateFormFields(duplicates);
    return duplicates;
  };

  function serializeShortcuts() {
    const joinTimeBackNext = valueTimeBackNextId.toString();
    const joinPlayStop = joinShortcutsFirstAndSecond(
      listFirstShortcut,
      valuePlayStopFirstId,
      listSecondShortcut,
      valuePlayStopSecondId
    );
    const joinActionBackTime = joinShortcutsFirstAndSecond(
      listFirstShortcut,
      valueActionBackTimeFirstId,
      listSecondShortcut,
      valueActionBackTimeSecondId
    );
    const joinActionNextTime = joinShortcutsFirstAndSecond(
      listFirstShortcut,
      valueActionNextTimeFirstId,
      listSecondShortcut,
      valueActionNextTimeSecondId
    );
    const joinSlowSpeed = joinShortcutsFirstAndSecond(
      listFirstShortcut,
      valueSlowSpeedFirstId,
      listSecondShortcut,
      valueSlowSpeedSecondId
    );
    const joinFastSpeed = joinShortcutsFirstAndSecond(
      listFirstShortcut,
      valueFastSpeedFirstId,
      listSecondShortcut,
      valueFastSpeedSecondId
    );

    const playStopAux: IShortcut = { name: "playStop", value: joinPlayStop };

    const actionBackTimeAux: IShortcut = {
      name: "actionBackTime",
      value: joinActionBackTime,
    };

    const actionNextTimeAux: IShortcut = {
      name: "actionNextTime",
      value: joinActionNextTime,
    };
    const timeBackNextAux: IShortcut = {
      name: "timeBackNext",
      value: joinTimeBackNext,
    };
    const slowSpeedAux: IShortcut = { name: "slowSpeed", value: joinSlowSpeed };
    const fastSpeedAux: IShortcut = { name: "fastSpeed", value: joinFastSpeed };

    const auxList: Array<IShortcut> = [];
    auxList.push(playStopAux);
    auxList.push(actionBackTimeAux);
    auxList.push(actionNextTimeAux);
    auxList.push(timeBackNextAux);
    auxList.push(slowSpeedAux);
    auxList.push(fastSpeedAux);

    findDuplicates(auxList);

    const valueSet = new Set();
    for (const item of auxList) {
      if (valueSet.has(item.value)) {
        return addToast({
          type: "error",
          title: "Aviso",
          description: `As configurações não podem ser duplicada!`,
        });
      }
      valueSet.add(item.value);
    }
    setLocalstorageToShortcuts(auxList);

    return JSON.stringify(auxList);
  }

  function setLocalstorageToShortcuts(listShortcuts: Array<IShortcut>) {
    for (let i = 0; i < listShortcuts.length; i++) {
      if (listShortcuts[i]?.name === "playStop") {
        insertTolocalStorage("GlobalPlayStop", listShortcuts[i].value);
      }
      if (listShortcuts[i]?.name === "actionNextTime") {
        insertTolocalStorage("GlobalActionNextTime", listShortcuts[i].value);
      }
      if (listShortcuts[i]?.name === "actionBackTime") {
        insertTolocalStorage("GlobalActionBackTime", listShortcuts[i].value);
      }
      if (listShortcuts[i]?.name === "slowSpeed") {
        insertTolocalStorage("GlobalSlowSpeed", listShortcuts[i].value);
      }
      if (listShortcuts[i]?.name === "fastSpeed") {
        insertTolocalStorage("GlobalFastSpeed", listShortcuts[i].value);
      }
      if (listShortcuts[i]?.name === "timeBackNext") {
        insertTolocalStorage("GlobalTimeBackNext", listShortcuts[i].value);
      }
    }
  }

  function joinShortcutsFirstAndSecond(
    listFirstShortcut: Array<IDropDown>,
    valueFirstId: number,
    listSecondShortcut: Array<IDropDown>,
    valueSecondId: number
  ) {
    let stringToValue = "";
    if (valueFirstId !== 0) {
      stringToValue = listFirstShortcut[valueFirstId]?.name + "+";
    }

    if (valueSecondId === 13) {
      stringToValue += "ArrowLeft";
    } else if (valueSecondId === 14) {
      stringToValue += "ArrowUp";
    } else if (valueSecondId === 15) {
      stringToValue += "ArrowRight";
    } else if (valueSecondId === 16) {
      stringToValue += "ArrowDown";
    } else {
      stringToValue += listSecondShortcut[valueSecondId]?.name;
    }

    return stringToValue;
  }

  function separateFirstAndSecond(listShortcuts: Array<IShortcut>) {
    const playStopAux = listShortcuts.find((x) => x?.name === "playStop")?.value;
    const playStopAuxSplit = playStopAux?.split("+");
    mapShortcutState(
      playStopAuxSplit,
      setPlayStopFirstId,
      setPlayStopFirstName,
      setPlayStopSecondId,
      setPlayStopSecondName
    );

    const actionBackTimeAux = listShortcuts.find(
      (x) => x?.name === "actionBackTime"
    )?.value;
    const actionBackTimeSplit = actionBackTimeAux?.split("+");

    mapShortcutState(
      actionBackTimeSplit,
      setActionBackTimeFirstId,
      setActionBackTimeFirstName,
      setActionBackTimeSecondId,
      setActionBackTimeSecondName
    );

    const actionNextTimeAux = listShortcuts.find(
      (x) => x?.name === "actionNextTime"
    )?.value;
    const actionNextTimeSplit = actionNextTimeAux?.split("+");
    mapShortcutState(
      actionNextTimeSplit,
      setActionNextTimeFirstId,
      setActionNextTimeFirstName,
      setActionNextTimeSecondId,
      setActionNextTimeSecondName
    );

    const slowSpeedAux = listShortcuts.find((x) => x?.name === "slowSpeed")?.value;
    const slowSpeedSplit = slowSpeedAux?.split("+");
    mapShortcutState(
      slowSpeedSplit,
      setSlowSpeedFirstId,
      setSlowSpeedFirstName,
      setSlowSpeedSecondId,
      setSlowSpeedSecondName
    );

    const fastSpeedAux = listShortcuts.find((x) => x?.name === "fastSpeed")?.value;
    const fastSpeedSplit = fastSpeedAux?.split("+");
    mapShortcutState(
      fastSpeedSplit,
      setFastSpeedFirstId,
      setFastSpeedFirstName,
      setFastSpeedSecondId,
      setFastSpeedSecondName
    );

    const timeBackNextAux = listShortcuts.find(
      (x) => x?.name === "timeBackNext"
    )?.value;

    const timeBackNextAuxId: number =
      timeBackNextAux === "" ? 0 : parseInt(timeBackNextAux as string);
    setTimeBackNextId(timeBackNextAuxId);
    const timeBackNextAuxName = listSecondsToNextBack.find(
      (x) => x?.id === timeBackNextAuxId
    )?.name;
    setTimeBackNextName(
      timeBackNextAuxName === undefined ? "5 segundos" : timeBackNextAuxName
    );
  }

  function mapShortcutState(
    array: string[] | undefined,
    setOneId: (e: number) => unknown,
    setOneName: (e: string) => unknown,
    setTwoId: (e: number) => unknown,
    setTwoName: (e: string) => unknown
  ) {
    if (array?.length === 1) {
      const name2: string = array[0];
      const getIdList = listSecondShortcut.find((x) => x?.name === name2)?.id;
      const id: number = getIdList === undefined ? 0 : (getIdList as number);
      setTwoId(id);
      setTwoName(mapArrowsShortcut(name2));

      // setando "nenhum" no primeiro drop quando atalho é apenas uma tecla
      const shortcutNenhum = listFirstShortcut[0];
      setOneId(shortcutNenhum?.id);
      setOneName(shortcutNenhum?.name);
    } else if (array?.length === 2) {
      const name1: string = array[0];
      const getIdList1 = listFirstShortcut.find((x) => x?.name === name1)?.id;
      const id1: number = getIdList1 === undefined ? 0 : (getIdList1 as number);
      setOneId(id1);
      setOneName(name1);

      const name2: string = array[1];
      const name22 = mapArrowsShortcut(name2);
      const getIdList2 = listSecondShortcut.find((x) => x?.name === name22)?.id;

      const id2: number = getIdList2 === undefined ? 0 : (getIdList2 as number);
      setTwoId(id2);
      setTwoName(name22);
    }
  }

  function mapArrowsShortcut(shortcut: string) {
    if (shortcut === "ArrowLeft") {
      shortcut = "Seta esquerda";
    } else if (shortcut === "ArrowUp") {
      shortcut = "Seta cima";
    } else if (shortcut === "ArrowRight") {
      shortcut = "Seta direita";
    } else if (shortcut === "ArrowDown") {
      shortcut = "Seta baixo";
    }
    return shortcut;
  }

  const [configurationsList, setConfigurationsList] = useState<any>([]);
  const [labelList, setLabelList] = useState<any>([]);
  const [search, setSearch] = useState<any>("");
  const [filterList, setFilterList] = useState<any>([]);
  const [showModal, setShowModal] = useState(false);
  const [passwordType, setPasswordType] = useState<typesInput>("password");
  const [itemEdit, setItemEdit] = useState<any>();
  const [editedValue, setEditedValue] = useState<any>("");
  const [filePathMark, setFilePathMark] = useState<any>("");
  const [file, setFile] = useState<any>();
  const [typeMark, setTypeMark] = useState(1);
  const [deadlineOff, setDeadlineOff] = useState<boolean>(false);

  const getAllConfigurationsByCustomerId = async () => {
    const { data, status } = await SystemConfigurationService.getAllByCustomer(
      customerId
    );
    if (status) {
      const list = data.filter((item: any) => item.visible);
      dispatch(updateGlobalConfigurationsAction(data));
      setConfigurationsList(list);
      setFilterList(list);
    }
  };

  const getWaterMark = async () => {
    const { data, success } = await SystemConfigurationService.getWatermark(1);

    if (success) {
      setTypeMark(data.layout);
      setLabelList(data.label.split(";"));
      setFilePathMark(data.filepath);
    }
  };

  useEffect(() => {
    getAllConfigurationsByCustomerId();
    getWaterMark();
  }, []);

  useEffect(() => {
    setSelectedListFirstShortcut(listFirstShortcut);
    setSelectedListSecondShortcut(listSecondShortcut);
    setSelectedListSecondsToNextBack(listSecondsToNextBack);
    setSelectedListPagination(listPagination);
    onLoadList();
  }, [showModal]);

  function hasDuplicateLabel(arr: { name: string, label: string, type: string }[]): boolean {
    const labels = arr.map(item => item.label);
    return labels.some((label, index) => labels.indexOf(label) !== index);
  }
  const formatWithMask = (value: string | null) => {
    if (!value) {
      return ""
    }
    return value.replace(/\d/g, '\\d')
      .replace(/\./g, '\\.')
      .replace(/\,/g, '\\,')
      .replace(/\//g, '\\/')
      .replace(/\s+/g, '\\s*')
  }
  const putConfiguration = async () => {
    if (itemEdit.name !== "ProcedureMask" && !editedValue && editedValue !== 0) {
      setFieldError(true);
      return addToast({
        type: "warning",
        title: "Aviso",
        description:
          "Para atualizar a configuração é necessário preencher os campos destacados",
      });
    }

    if (file && file.type !== "image/png") {
      return addToast({
        type: "warning",
        title: "Aviso",
        description: `Tipo de arquivo invalido é necessário escolher um arquivo .png`,
      });
    }

    setFieldError(false);
    dispatch(showLoading());
    setLoading(true);

    if (itemEdit?.name === "TranscriptionProvider") {
      await SystemConfigurationService.patch(editedValue, itemEdit.id);
      await SystemConfigurationService.patch(withIndexing ? '1' : '0', '141');
    }
    
    if (
      itemEdit?.name !== "ServiceIntegrationAuthentication" &&
      itemEdit?.name !== "ServiceIntegrationImportation" &&
      itemEdit?.name !== "ServiceIntegrationNotification" &&
      itemEdit?.name !== "TranscriptionProvider"
    ) {
      await SystemConfigurationService.patch(
        itemEdit?.name === "Shortcuts"
          ? serializeShortcuts()
          : itemEdit?.name === "LogoCustomer"
            ? customerLogo
            : itemEdit?.name === "ProcedureMask"
              ? formatWithMask(editedValue)
              : editedValue,
        itemEdit?.id
      );
    }

    if (itemEdit?.name === "ServiceIntegrationAuthentication") {
      await SystemConfigurationService.patch(
        serviceIntegrationAuthenticationValue,
        itemEdit?.id
      );
    }
    if (itemEdit?.name === "ServiceIntegrationImportation") {
      const isDuplicate = hasDuplicateLabel(JSON.parse(serviceIntegrationImportationValue).parametros)
      if (isDuplicate) {
        return addToast({
          type: "error",
          title: "Aviso",
          description:
            "Não é possivel ter dois itens com o mesmo label.",
        });
      }
      await SystemConfigurationService.patch(
        serviceIntegrationImportationValue,
        itemEdit?.id
      );
    }
    if (itemEdit?.name === "ServiceIntegrationNotification") {   
      const isDuplicate = hasDuplicateLabel(JSON.parse(serviceIntegrationNotificationValue).parametros)
      if (isDuplicate) {
        return addToast({
          type: "error",
          title: "Aviso",
          description:
            "Não é possivel ter dois itens com o mesmo label.",
        });
      } 
      await SystemConfigurationService.patch(
        serviceIntegrationNotificationValue,
        itemEdit?.id
      );
    }

    if (itemEdit?.name === "PaginationList") {
      insertTolocalStorage("PaginationList", editedValue);
    }

    if (itemEdit?.name === "CustomerProcedureDeadline") {
      insertTolocalStorage("CustomerProcedureDeadline", editedValue);
    }

    if (itemEdit?.name === "Watermark") {
      const labelListString = labelList.join(";");

      if (file) {
        Resizer.imageFileResizer(
          file,
          80,
          102,
          "PNG",
          100,
          0,
          async (fileResized: any) => {
            await SystemConfigurationService.uploadFile(
              fileResized,
              containerName,
              file?.name
            );
          },
          "file",
          50,
          102
        );
      }
      await SystemConfigurationService.updateWatermark(
        1,
        labelListString || null,
        typeMark,
        filePathMark
      );
    }

    await getAllConfigurationsByCustomerId();
    setSelectedListFirstShortcut(listFirstShortcut);
    setSelectedListSecondShortcut(listSecondShortcut);
    setSelectedListSecondsToNextBack(listSecondsToNextBack);
    setSelectedListPagination(listPagination);
    await onLoadList();
    setShowModal(false);
    setPasswordType("password");
    dispatch(hideLoading());
    setLoading(true);

    addToast({
      type: "success",
      title: "Configuração atualizada",
      description: "A configuração foi atualizada com sucesso",
    });
  };

  const erroAplay = async () => {
    if (!!errorSIAuthent) {
      addToast({
        type: "error",
        title: "JSON inválido",
        description: "Por favor, verifique os parametros dígitos",
      });
    }
  };

  const selectType = (type: number) => {
    setTypeMark(type);
  };

  const [customerLogo, setCustomerLogo] = useState(null);
  const [errorSIAuthent, setErrorSIAuthent] = useState<any>();


  //========itemEdit.name === "ServiceIntegrationAuthentication"
  const [serviceIntegrationAuthentication, setServiceIntegrationAuthentication] = useState<any>();
  const [serviceIntegrationAuthenticationValue, setServiceIntegrationAuthenticationdValue] = useState(serviceIntegrationAuthentication || '');

  //========itemEdit.name === "ServiceIntegrationImportation"
  const [serviceIntegrationImportation, setServiceIntegrationImportation] = useState<any>();
  const [serviceIntegrationImportationValue, setServiceIntegrationImportationValue] = useState(serviceIntegrationImportation || '');

  //========itemEdit.name === "ServiceIntegrationNotification"
  const [serviceIntegrationNotification, setServiceIntegrationNotification] = useState<any>();
  const [serviceIntegrationNotificationValue, setServiceIntegrationNotificationValue] = useState(serviceIntegrationNotification || '');


  const closeAll = () => {    
    setShowModal(false);
    setFieldError(false);
    setCustomerLogo(null);
    setEditedValue("0");
    dispatch(hideLoading());
    setPasswordType("password");
    setShortCutsErrorPlayStop(false);
    setShortCutsErrorActionBackTime(false);
    setShortCutsErrorActionNextTime(false);
    setShortCutsErrorSlowSpeed(false);
    setShortCutsErrorFastSpeed(false);
    setErrorSIAuthent(null);
    setServiceIntegrationNotificationValue(serviceIntegrationNotification)
    setServiceIntegrationImportationValue(serviceIntegrationImportation)
  };

  const onPressEdit = (item: any) => {
    if (
      item.name === "Watermark" ||
      item.name === "FacialRecognition" ||
      item.name === "NotificationAuto" ||
      item.name === "TranscriptionSummary"
    ) {
      setSelectedYesOrNoName(item.value === "1" ? "Sim" : "Não");
    }

    if (item.name === "LogoCustomer") {
      setSelectedYesOrNoName(!!item.value ? "Sim" : "Não");
      setCustomerLogo(item.value);
    }

    if (item.name === "ServiceIntegrationAuthentication") {
      setServiceIntegrationAuthentication(item.value)
    }
    if (item.name === "ServiceIntegrationImportation") {
      setServiceIntegrationImportation(item.value)
    }
    if (item.name === "ServiceIntegrationNotification") {
      setServiceIntegrationNotification(item.value)
    }

    setItemEdit(item);
    setEditedValue(item.value);
    setShowModal(true);
  };

  const onChangeFiles = (file: any) => {
    if (file) {
      Resizer.imageFileResizer(
        file,
        20,
        100,
        "PNG",
        100,
        0,
        async (fileResized: any) => {
          const reader = new FileReader();

          reader.onload = (e: any) => {
            setCustomerLogo(e.target.result);
          };

          reader.readAsDataURL(fileResized);
        },
        "file",
        20,
        100
      );
    }
  };

  const getNameIfIdMatches = (array: any[], editedValue: string) => {
    const foundItem = array.find((item) => item?.id === Number(editedValue));
    return foundItem ? foundItem.name : null;
  };

  const whatIsEdit = (name: string) => {
    switch (name) {
      case "LoginConfiguration":
        return (
          <LoginConfigurationCustom
            editedValue={editedValue}
            setEditedValue={setEditedValue}
          />
        );
      case "TranscriptionSummary":
        return (
          <DropdownGlobal
            labelName={"Resumo da transcrição"}
            options={listYesOrNoAux}
            error={fieldError}
            setSelected={setEditedValue}
            required={false}
            editDropdown={selectedYesOrNoName}
            defaultValue={selectedYesOrNoName}
          />
        );
      case "TranscriptionProvider":
        return (
          <TranscriptionProvider
            editConfig={editConfig}
            editedValue={editedValue}
            fieldError={fieldError}
            getNameIfIdMatches={getNameIfIdMatches}
            setEditedValue={setEditedValue}
            withIndexing={withIndexing}
            setWithIndexing={setWithIndexing}
          />
        );
      case "StorageProvider1":
        return (
          <StorageProviderCustom
            description={itemEdit.description}
            selectedStorageProviderList={listProviderAux}
            setEditedValue={setEditedValue}
            getNameIfIdMatches={getNameIfIdMatches}
            editedValue={editedValue}
            fieldError={fieldError}
            passwordType={passwordType}
            setPasswordType={setPasswordType}
          />
        );
      case "LogoCustomer":
        return (
          <LogoCustom
            customerLogo={customerLogo}
            editedValue={editedValue}
            onChangeFiles={onChangeFiles}
            setCustomerLogo={setCustomerLogo}
            setEditedValue={setEditedValue}
            fieldError={fieldError}
            selectedYesOrNoName={selectedYesOrNoName}
          />
        );
      case "Watermark":
        return (
          <WatermarkCustom
            editedValue={editedValue}
            fieldError={fieldError}
            selectType={selectType}
            selectedYesOrNoName={selectedYesOrNoName}
            setEditedValue={setEditedValue}
            setFile={setFile}
            typeMark={typeMark}
            labelList={labelList}
            setLabelList={setLabelList}
            filePathMark={filePathMark}
          />
        );
      case "ServiceIntegrationAuthentication":
        return (
          <ServiceIntegrationAuthentication
            serviceIntegrationAuthentication={serviceIntegrationAuthentication}
            textFieldValue={serviceIntegrationAuthenticationValue}
            setTextFieldValue={setServiceIntegrationAuthenticationdValue}
            errorSIAuthent={errorSIAuthent}
            setErrorSIAuthent={setErrorSIAuthent}
          />
        );
      case "ServiceIntegrationImportation":
        return (
          <ServiceIntegrationImportation
            serviceIntegrationImportation={serviceIntegrationImportation}
            textFieldValue={serviceIntegrationImportationValue}
            setTextFieldValue={setServiceIntegrationImportationValue}
            errorSIAuthent={errorSIAuthent}
            setErrorSIAuthent={setErrorSIAuthent}
          />
        );
      case "ServiceIntegrationNotification":
        return (
          <ServiceIntegrationNotification
            serviceIntegrationNotification={serviceIntegrationNotification}
            textFieldValue={serviceIntegrationNotificationValue}
            setTextFieldValue={setServiceIntegrationNotificationValue}
            errorSIAuthent={errorSIAuthent}
            setErrorSIAuthent={setErrorSIAuthent}
          />
        );
      case "NotificationAuto":
        return (
          <DropdownGlobal
            labelName={"Notificação automática"}
            options={listYesOrNoAux}
            error={fieldError}
            setSelected={setEditedValue}
            required={false}
            editDropdown={selectedYesOrNoName}
            defaultValue={selectedYesOrNoName}
          />
        );
      case "DaysToGetProcedure":
        return (
          <DropdownGlobal
            labelName={"Período máximo de pesquisa"}
            options={listDaysAux}
            error={fieldError}
            setSelected={setEditedValue}
            required={false}
            editDropdown={selectedDaysToGetProcedureName}
            defaultValue={selectedDaysToGetProcedureName}
          />
        );
      case "FacialRecognition":
        return (
          <DropdownGlobal
            labelName={"Reconhecimento facial"}
            options={listYesOrNoAux}
            error={fieldError}
            setSelected={setEditedValue}
            required={false}
            editDropdown={selectedYesOrNoName}
            defaultValue={selectedYesOrNoName}
          />
        );
      case "PaginationList":
        return (
          <DropdownGlobal
            labelName={"Registros por página nas listagens"}
            options={selectedListPagination}
            error={fieldError}
            setSelected={(e: any) => setEditedValue(e)}
            required={false}
            editDropdown={valuePaginationName}
            defaultValue={valuePaginationName}
          />
        );
      case "Shortcuts":
        return (
          <ShortCuts
            listSecondsToNextBack={selectedListSecondsToNextBack}
            listFirstShortcut={selectedListFirstShortcut}
            listSecondShortcut={selectedListSecondShortcut}
            setTimeBackNextId={setTimeBackNextId}
            setPlayStopFirstId={setPlayStopFirstId}
            setPlayStopSecondId={setPlayStopSecondId}
            setActionBackTimeFirstId={setActionBackTimeFirstId}
            setActionBackTimeSecondId={setActionBackTimeSecondId}
            setActionNextTimeFirstId={setActionNextTimeFirstId}
            setActionNextTimeSecondId={setActionNextTimeSecondId}
            setFastSpeedFirstId={setFastSpeedFirstId}
            setFastSpeedSecondId={setFastSpeedSecondId}
            setSlowSpeedFirstId={setSlowSpeedFirstId}
            setSlowSpeedSecondId={setSlowSpeedSecondId}
            valueTimeBackNextName={valueTimeBackNextName}
            valuePlayStopFirstName={valuePlayStopFirstName}
            valuePlayStopSecondName={valuePlayStopSecondName}
            valueActionBackTimeFirstName={valueActionBackTimeFirstName}
            valueActionBackTimeSecondName={valueActionBackTimeSecondName}
            valueActionNextTimeFirstName={valueActionNextTimeFirstName}
            valueActionNextTimeSecondName={valueActionNextTimeSecondName}
            valueFastSpeedFirstName={valueFastSpeedFirstName}
            valueFastSpeedSecondName={valueFastSpeedSecondName}
            valueSlowSpeedFirstName={valueSlowSpeedFirstName}
            valueSlowSpeedSecondName={valueSlowSpeedSecondName}
            shortCutsErrorplayStop={shortCutsErrorplayStop}
            shortCutsErrorActionBackTime={shortCutsErrorActionBackTime}
            shortCutsErrorActionNextTime={shortCutsErrorActionNextTime}
            shortCutsErrorSlowSpeed={shortCutsErrorSlowSpeed}
            shortCutsErrorFastSpeed={shortCutsErrorFastSpeed}
          />
        );
      case "CustomerProcedureDeadline":
        return (
          <ContainerBody>
            <ContainerInputs>
              <InputGeneral
                id="CustomerProcedureDeadlineIdInput"
                error={fieldError}
                label={itemEdit.description}
                value={editedValue}
                onChange={(e) => setEditedValue(e)}
                required={true}
                disabled={deadlineOff}
                type="number"
                width={381}
                maxLength={499}
              />
            </ContainerInputs>
          </ContainerBody>
        );
      case "ProcedureMask":
        return (
          <ProcedureMask setEditedValue={setEditedValue} editedValue={editedValue} />
        );
      default:
        return <div />;
    }
  };

  useEffect(() => {
    if (search) {
      setFilterList(
        configurationsList.filter((x: any) =>
          x.description.toLowerCase().includes(search.toLowerCase())
        )
      );
    } else {
      setFilterList(configurationsList);
    }
  }, [search]);

  return (
    <Container>
      <ContainerLeftMenu>
        <Administration />
      </ContainerLeftMenu>
      <AdminArea
        title={"Configurações globais"}
        InputSearchLabel="Buscar por"
        InputSearchOnChange={(text: any) => setSearch(text)}
        searchText={search}
        list={filterList}
        onClick={(item: any) => onPressEdit(item)}
        setItemReturnClick={() => { }}
        setOpenModal={setShowModal}
        permissionName={FORMS_ADMIN.SYSTEM_CONFIGURATION}
        onChangeSituation={() => { }}        
        showStatus={false}
        displayAtivoFilter={false}
        description={true}
      />
      <DrawerDefault
        openDrawer={showModal}
        setOpenDrawer={closeAll}
        onClose={() => closeAll()}
        title={
          itemEdit && itemEdit.name === "Shortcuts"
            ? itemEdit.description
            : "Editar configuração"
        }
        onApply={!!errorSIAuthent ? erroAplay : putConfiguration}
        children={
          <>{itemEdit && itemEdit.name ? whatIsEdit(itemEdit.name) : <div />}</>
        }
      />
      <LoadSpinner loading={loading} zindex={1300}/>
    </Container>
  );
}
