/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Modal from "react-modal";
import {
  Container,
  ContainerClose,
  ContainerHeader,
  ContainerMenu,
  Label,
  LabelLink,
  Logo,
  PictureContainer,
  Separator,
  Subtitle,
  TitleHeader,
  TitleMenu,
  customStyles,
  styleImage,
} from "./style";
import close from "../../assets/svg/close.svg";
import {
  deleteFromlocalStorage,
  getValueFromlocalStorage,
} from "../../utils/sessionStorageEncrypt";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "react-avatar";
import { hideLoading, showLoading } from "../../store/modules/login/actions";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router-dom";
import { setScreenStatus } from "../../store/modules/screen_controll/actions";
import { logout, storageBaseUrl } from "../../services/api";
import { updateUser } from "../../store/modules/user/actions";
import { MyAccount } from "../../pages/app/MyAccount";
import logo from "../../assets/Kenta_4.0_Judiciário.png";
import { LogOperation } from "../../utils/entities/logOperation";

interface ModalType {
  isOpen: boolean;
  onClose: () => void;
  name: string;
  user: any;
  profile: string;
  changePicture: number;
  setChangePicture: React.Dispatch<React.SetStateAction<number>>;
  containerName: string;
  setContainerName: React.Dispatch<React.SetStateAction<string>>;
}

export const onClickSair = async ({
  setIsLoading,
  dispatch,
  navigate,
}: any) => {
  dispatch(showLoading());
  setIsLoading(true);
  await LogOperation.postLogOperation("logout", 5, 5);
  const user = getValueFromlocalStorage("@Kenta:user");
  if (user) {
    const parsedUser = JSON.parse(user);
    localStorage.removeItem("IndexProfileSelected");

    deleteFromlocalStorage("timeToCount", "encrypt-time");
    try {
      await logout(parsedUser.hash);
    } catch (error) {
      console.log(error);
    }
  }

  localStorage.clear();
  localStorage.clear();
  navigate("/");
  setIsLoading(false);
  dispatch(hideLoading());
};

const ProfileModal: React.FC<ModalType> = ({
  isOpen,
  onClose,
  name,
  user,
  profile,
  changePicture,
  setChangePicture,
  containerName,
  setContainerName,
}) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const [userItem, setUserItem] = useState<any>();
  const [userName, setUserName] = useState(name);
  const [isLoading, setIsLoading] = useState(false);

  const [_unit, setUnit] = useState<any>();
  const [avatarState, setAvatarState] = useState<string>("");
  const [_photoName, setPhotoName] = useState<string>("");

  const navigate = useNavigate();

  const dispatch = useDispatch<any>();

  const { policeUnit } = useSelector((state: any) => state.policeUnit);
  const { user: userRedux } = useSelector((e: any) => e.user);

  const [userId] = useState(user);

  const searchUser = () => {
    const user = getValueFromlocalStorage("@Kenta:user");
    const unitStorage = policeUnit;

    if (user) {
      const passedUser = JSON.parse(user);
      setUserName(passedUser.name);
      setUserItem(passedUser);

      if (!userRedux) {
        dispatch(updateUser(passedUser));
      }
    }

    if (unitStorage) {
      setUnit(unitStorage);
    }

    changeAvatar();
  };

  useEffect(() => {
    if (isOpen === true) searchUser();
  }, [isOpen]);

  useEffect(() => {
    searchUser();
  }, []);

  useEffect(() => {
    changeAvatar();
  }, [changePicture]);

  useEffect(() => {
    if (changePicture > 0) {
      location.reload();
      changeAvatar();
    }
  }, [changePicture]);

  const changeAvatar = () => {
    const user = getValueFromlocalStorage("@Kenta:user");
    const parsedUser = user ? JSON.parse(user) : null;
    const containerNameAux = containerName ?? "riograndedosul";
    const storageBase = `${storageBaseUrl}/${containerNameAux}/${parsedUser?.picture?.replace(
      /\\/gm,
      "/"
    )}`;

    setAvatarState("");
    setAvatarState(storageBase);
  };

  function clearAllCookies() {
    const cookies = document.cookie.split(";");

    for (const cookie of cookies) {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
  }

  return (
    <>
      <Modal
        testId="profile_modal_container"
        isOpen={isOpen}
        style={{ ...customStyles }}
        contentLabel="Example Modal"
        ariaHideApp={false}
        shouldCloseOnEsc={true}
      >
        <Container>
          <div>
          <ContainerClose>
            <img
              src={close}
              style={{ ...styleImage }}
              onClick={() => onClose()}
              alt=""
            />
          </ContainerClose>
          <ContainerHeader>
            {userItem && userItem.picture && userId ? (
              <PictureContainer
                size={60}
                picture={avatarState}
                style={{ cursor: "default" }}
              />
            ) : (
              <Avatar
                name={userItem ? userItem.name : "Avatar"}
                size="60"
                round={true}
                style={{ cursor: "default" }}
              />
            )}
            <TitleHeader>{userName}</TitleHeader>
            <Subtitle>{profile}</Subtitle>
          </ContainerHeader>
          {isLoading ? (
            <ReactLoading type="spin" color="#F2A60D" width={24} height={24} />
          ) : (
            <ContainerMenu>
              <Label
                style={{ cursor: "pointer" }}
                onClick={() => setOpenDrawer(true)}
              >
                Minha conta
              </Label>
              <Separator />
              <TitleMenu>Suporte</TitleMenu>
              <LabelLink
                href="https://www.kenta.com.br/contato/"
                target="blank"
              >
                Contato do suporte
              </LabelLink>
              {/* 1.features.bugs */}
              <Label>Versão 1.9.108 - 11/10/2024</Label>

              <Separator />
              <Label
                id="profile_modal_sair"
                onClick={() => {
                  onClickSair({ setIsLoading, dispatch, navigate });
                  dispatch(setScreenStatus(false));
                  clearAllCookies();
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                Sair
              </Label>
            </ContainerMenu>
          )}
          </div>
          <Logo style={{alignSelf:'center',width:'100px'}}src={logo}/>
        </Container>
      </Modal>
      <MyAccount
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        changePicture={changePicture}
        setChangePicture={setChangePicture}
        containerName={containerName}
        setContainerName={setContainerName}
        setPhotoName={setPhotoName}
      />
    </>
  );
};

export default ProfileModal;
