import * as Sentry from "@sentry/react";

const initSentryLocalHost = () => {
    Sentry.init({
        dsn: "https://237b9e5823e769e3f7c48ae96ff5533b@o4508025049120768.ingest.us.sentry.io/4508054280273920",
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        tracesSampleRate: 1.0,
        tracePropagationTargets: [
          "http://localhost:5178/",
        ],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
};
export default initSentryLocalHost;
