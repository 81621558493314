import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px; 
  width: 600px;
  padding: 25px;
`;

export const ContainerBody = styled.div`
 width: 100% - 48px;
overflow: hidden;
padding: 0 24px;
padding-bottom: 20px;
`;

export const ContainerList = styled.div`
  width: 100%;
  height: 64vh;
  padding-top: 16px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;

  @media screen and (min-height: 850px) {
    height: 70vh;
  }
`;