/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment, useEffect, useState } from "react";

import {
  Container,
  Content,
  Header,
  Body,
  ContainerByDate,
  BoxTime,
  Typography,
  BottomContainer,
  BodyBottomContainer,
  BodyBottom,
} from "./styles";
import {
  LoadingComponent,
  PageHeader,
  SearchEmpty,
} from "@kentarepo/kcf-assets";
import { usePermissions } from "../../hooks/permission";
import { FORMS_HEARING } from "../../utils/entities/permission";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";
import { useTheme } from "@mui/material/styles";

import MainCard from "../../ui-components/cards/MainCard";
import { IconFile } from "@tabler/icons-react";
import { gridSpacing } from "../../utils/contants";
import { ChartItem, dataAnalysisServices } from "../../services/DataAnalysis";
import { useSelector } from "react-redux";
import {
  IScheduleMenuList,
  ShowIcon,
  capitalizeFirstLetter,
  scheduleMenuList,
} from "./constants";
import { LoadingContainer } from "../CreateAttachmentTemplate/styles";
import { IDashboard } from "./interface";

const label = "Dashboard";

export const AdminDashboard: React.FC<IDashboard> = ({ isFull }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const theme = useTheme();
  const matchDownXs = useMediaQuery(theme.breakpoints.down("sm"));
  const { userCustomerProfile } = useSelector((store: any) => store.profile);
  const [dataDashboardFirst, setDataDashboardFirst] = useState<
    Array<ChartItem>
  >([]);
  const [dataDashboardSecond, setDataDashboardSecond] = useState<
    Array<ChartItem>
  >([]);
  const [dataDashboardThird, setDataDashboardThird] = useState<
    Array<ChartItem>
  >([]);
  const [dataDashboardFourth, setDataDashboardFourth] = useState<
    Array<ChartItem>
  >([]);
  const [schedule, setSchedule] = useState<number>(1);
  const [description, setDescription] = useState<string>("");
  const [information, setInformation] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null);

  const handleClickSort = (
    event: React.MouseEvent<HTMLButtonElement> | undefined,
    descriptionItem: string
  ) => {
    setDescription(descriptionItem);
    setAnchorEl(event?.currentTarget);
  };

  const handleCloseSort = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    (async () => {
      setInformation(null);

      if (userCustomerProfile?.placeId) {
        setLoading(true);
        const { data } = await dataAnalysisServices.GetDashboardInformation(
          userCustomerProfile?.placeId,
          schedule
        );

        if (data) {
          const first = data.slice(0, 2);
          const second = data.slice(2, 4);
          const third = data.slice(4, 6);
          const fourth = data.slice(6, 8);
          setDataDashboardFirst(first);
          setDataDashboardSecond(second);
          setDataDashboardThird(third);
          setDataDashboardFourth(fourth);
        }
        setLoading(false);
      }
    })();
  }, [userCustomerProfile?.placeId, schedule]);

  const handleGetMoreInformation = async (description: string) => {
    handleCloseSort();

    setLoading(true);
    const { data, success } =
      await dataAnalysisServices.GetDashboardMoreInformation(
        userCustomerProfile?.placeId,
        schedule,
        description
      );

    if (success) {
      setInformation({ title: description, data });
    }
    setLoading(false);
  };

  const blockSX = {
    display: "flex",
    p: 2.5,
    borderLeft: "1px solid ",
    borderBottom: "1px solid ",
    borderTop: "1px solid ",
    borderRight: "1px solid ",
    height: 180,
    alignItem: "center",
    textTransform: "capitalize",
    borderLeftColor: theme.palette.grey[200],
    borderRightColor: theme.palette.grey[200],
    borderBottomColor: theme.palette.grey[200],
    borderTopColor: theme.palette.grey[200],
  };

  const { read } = usePermissions(FORMS_HEARING.HEARING_FILE);

  return (
    <Fragment>
      <Container isFull={isFull}>
        <PageHeader
          quantity={0}
          id="header"
          label={label}
          background="secondary"
          addClick={undefined}
          disabled={false}
        />
        {read ? (
          <Content>
            <Header>
              <ContainerByDate>
                {scheduleMenuList.map((item: IScheduleMenuList) => (
                  <BoxTime
                    id={item?.id}
                    selected={item?.id === schedule}
                    onClick={() => setSchedule(item?.id)}
                  >
                    {item.title}
                  </BoxTime>
                ))}
              </ContainerByDate>
              {/* <strong>Audiências</strong> */}
            </Header>
            <Body>
              <Box sx={{ width: "100%" }}>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12}>
                    <MainCard
                      content={false}
                      sx={{
                        "& svg": {
                          width: 25,
                          height: 25,
                          color: theme.palette.secondary.main,
                          borderRadius: "14px",
                          p: 1.25,
                        },
                      }}
                    >
                      <Grid container alignItems="center" spacing={0}>
                        {dataDashboardFirst.map((itemfirst) => (
                          <Grid item xs={12} sm={6} sx={blockSX}>
                            <Grid
                              container
                              alignItems="center"
                              spacing={1}
                              justifyContent={
                                matchDownXs ? "space-between" : "center"
                              }
                            >
                              <Grid
                                item
                                sx={{
                                  "& svg": {
                                    bgcolor:
                                      theme.palette.mode === "dark"
                                        ? theme.palette.background.default
                                        : "primary.light",
                                  },
                                }}
                              >
                                <ShowIcon
                                  description={capitalizeFirstLetter(
                                    itemfirst.description || "REGISTRADAS"
                                  )}
                                />
                              </Grid>
                              <Grid item sm zeroMinWidth>
                                <Typography variant="h5" align="left">
                                  {capitalizeFirstLetter(
                                    itemfirst.description || "REGISTRADAS"
                                  )}
                                </Typography>
                                <Typography
                                  color="#ff7300"
                                  variant="h5"
                                  align="left"
                                >
                                  {itemfirst.total || 0}
                                </Typography>
                              </Grid>
                              <Grid
                                sx={{
                                  marginTop: -20,
                                  marginRight: 5,
                                  width: 2,
                                  height: 2,
                                  cursor: "pointer",
                                  background: "none",

                                  "& svg": {
                                    width: 16,
                                    height: 16,
                                    color: "#000",
                                    borderRadius: "8px",
                                  },
                                }}
                              >
                                <IconButton
                                  onClick={(event) =>
                                    handleClickSort(
                                      event,
                                      capitalizeFirstLetter(
                                        itemfirst.description
                                      )
                                    )
                                  }
                                  size="large"
                                  aria-label="chat user details change"
                                >
                                  <MoreHorizTwoToneIcon />
                                </IconButton>
                                <Menu
                                  id="simple-menu"
                                  anchorEl={anchorEl as any}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  onClose={handleCloseSort}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  <MenuItem
                                    onClick={() =>
                                      handleGetMoreInformation(description)
                                    }
                                  >
                                    Ver detalhes
                                  </MenuItem>
                                </Menu>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid container alignItems="center" spacing={0}>
                        {dataDashboardSecond.map((item) => (
                          <Grid item xs={12} sm={6} sx={blockSX}>
                            <Grid
                              container
                              alignItems="center"
                              spacing={1}
                              justifyContent={
                                matchDownXs ? "space-between" : "center"
                              }
                            >
                              <Grid
                                item
                                sx={{
                                  "& svg": {
                                    bgcolor:
                                      theme.palette.mode === "dark"
                                        ? theme.palette.background.default
                                        : "primary.light",
                                  },
                                }}
                              >
                                <ShowIcon
                                  description={capitalizeFirstLetter(
                                    item.description || "REGISTRADAS"
                                  )}
                                />
                              </Grid>
                              <Grid item sm zeroMinWidth>
                                <Typography variant="h5" align="left">
                                  {capitalizeFirstLetter(
                                    item.description || "REGISTRADAS"
                                  )}
                                </Typography>
                                <Typography
                                  color="#ff7300"
                                  variant="h5"
                                  align="left"
                                >
                                  {item.total || 0}
                                </Typography>
                              </Grid>
                              <Grid
                                sx={{
                                  marginTop: -20,
                                  marginRight: 5,
                                  width: 2,
                                  height: 2,
                                  cursor: "pointer",
                                  background: "none",

                                  "& svg": {
                                    width: 16,
                                    height: 16,
                                    color: "#000",
                                    borderRadius: "8px",
                                  },
                                }}
                              >
                                <IconButton
                                  onClick={(event) =>
                                    handleClickSort(
                                      event,
                                      capitalizeFirstLetter(item.description)
                                    )
                                  }
                                  size="large"
                                  aria-label="chat user details change"
                                >
                                  <MoreHorizTwoToneIcon />
                                </IconButton>
                                <Menu
                                  id="simple-menu"
                                  anchorEl={anchorEl as any}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  onClose={handleCloseSort}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  <MenuItem
                                    sx={{
                                      boxShadow: "none",
                                    }}
                                    onClick={() =>
                                      handleGetMoreInformation(description)
                                    }
                                  >
                                    Ver detalhes
                                  </MenuItem>
                                </Menu>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </MainCard>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ width: "100%" }}>
                <Grid container spacing={gridSpacing}>
                  <Grid item xs={12}>
                    <MainCard
                      content={false}
                      sx={{
                        "& svg": {
                          width: 25,
                          height: 25,
                          color: theme.palette.secondary.main,
                          borderRadius: "14px",
                          p: 1.25,
                        },
                      }}
                    >
                      <Grid container alignItems="center" spacing={0}>
                        {dataDashboardThird.map((item) => (
                          <Grid item xs={12} sm={6} sx={blockSX}>
                            <Grid
                              container
                              alignItems="center"
                              spacing={1}
                              justifyContent={
                                matchDownXs ? "space-between" : "center"
                              }
                            >
                              <Grid
                                item
                                sx={{
                                  "& svg": {
                                    bgcolor:
                                      theme.palette.mode === "dark"
                                        ? theme.palette.background.default
                                        : "primary.light",
                                  },
                                }}
                              >
                                <ShowIcon
                                  description={capitalizeFirstLetter(
                                    item.description || "REGISTRADAS"
                                  )}
                                />
                              </Grid>
                              <Grid item sm zeroMinWidth>
                                <Typography variant="h5" align="left">
                                  {capitalizeFirstLetter(
                                    item.description || "REGISTRADAS"
                                  )}
                                </Typography>
                                <Typography
                                  color="#ff7300"
                                  variant="h5"
                                  align="left"
                                >
                                  {item.total || 0}
                                </Typography>
                              </Grid>
                              <Grid
                                sx={{
                                  marginTop: -20,
                                  marginRight: 5,
                                  width: 2,
                                  height: 2,
                                  cursor: "pointer",
                                  background: "none",

                                  "& svg": {
                                    width: 16,
                                    height: 16,
                                    color: "#000",
                                    borderRadius: "8px",
                                  },
                                }}
                              >
                                <IconButton
                                  onClick={(event) =>
                                    handleClickSort(
                                      event,
                                      capitalizeFirstLetter(item.description)
                                    )
                                  }
                                  size="large"
                                  aria-label="chat user details change"
                                >
                                  <MoreHorizTwoToneIcon />
                                </IconButton>
                                <Menu
                                  id="simple-menu"
                                  anchorEl={anchorEl as any}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  onClose={handleCloseSort}
                                  anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                  }}
                                  transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  <MenuItem
                                    onClick={() =>
                                      handleGetMoreInformation(description)
                                    }
                                  >
                                    Ver detalhes
                                  </MenuItem>
                                </Menu>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid container alignItems="center" spacing={0}>
                        {dataDashboardFourth.map((item) => (
                          <Grid item xs={12} sm={6} sx={blockSX}>
                            <Grid
                              container
                              alignItems="center"
                              spacing={1}
                              justifyContent={
                                matchDownXs ? "space-between" : "center"
                              }
                            >
                              <Grid
                                item
                                sx={{
                                  "& svg": {
                                    bgcolor:
                                      theme.palette.mode === "dark"
                                        ? theme.palette.background.default
                                        : "primary.light",
                                  },
                                }}
                              >
                                <ShowIcon
                                  description={capitalizeFirstLetter(
                                    item.description || "REGISTRADAS"
                                  )}
                                />
                              </Grid>
                              <Grid item sm zeroMinWidth>
                                <Typography variant="h5" align="left">
                                  {capitalizeFirstLetter(
                                    item.description || "REGISTRADAS"
                                  )}
                                </Typography>
                                <Typography
                                  color="#ff7300"
                                  variant="h5"
                                  align="left"
                                >
                                  {item.total || 0}
                                </Typography>
                              </Grid>
                              {item.description !== "DURAÇÃO MÉDIA" && (
                                <Grid
                                  sx={{
                                    marginTop: -20,
                                    marginRight: 5,
                                    width: 2,
                                    height: 2,
                                    cursor: "pointer",
                                    background: "none",

                                    "& svg": {
                                      width: 16,
                                      height: 16,
                                      color: "#000",
                                      borderRadius: "8px",
                                    },
                                  }}
                                >
                                  <IconButton
                                    onClick={(event) =>
                                      handleClickSort(
                                        event,
                                        capitalizeFirstLetter(item.description)
                                      )
                                    }
                                    size="large"
                                    aria-label="chat user details change"
                                  >
                                    <MoreHorizTwoToneIcon />
                                  </IconButton>
                                  <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl as any}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleCloseSort}
                                    anchorOrigin={{
                                      vertical: "bottom",
                                      horizontal: "right",
                                    }}
                                    transformOrigin={{
                                      vertical: "top",
                                      horizontal: "right",
                                    }}
                                  >
                                    <MenuItem
                                      onClick={() =>
                                        handleGetMoreInformation(description)
                                      }
                                    >
                                      Ver detalhes
                                    </MenuItem>
                                  </Menu>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                    </MainCard>
                  </Grid>
                </Grid>
              </Box>
            </Body>
            {information && information?.data && information?.data?.length ? (
              <BottomContainer>
                <Header>
                  <strong>{capitalizeFirstLetter(information?.title)}</strong>
                </Header>
                {information?.data?.length ? (
                  <BodyBottomContainer>
                    {information?.data.map((item: ChartItem) => (
                      <BodyBottom>
                        <MainCard
                          content={false}
                          sx={{
                            "& svg": {
                              width: 20,
                              height: 20,
                              color: theme.palette.secondary.main,
                              borderRadius: "14px",
                              p: 1.25,
                              bgcolor:
                                theme.palette.mode === "dark"
                                  ? theme.palette.background.default
                                  : "primary.light",
                            },
                            gap: 1,
                            padding: 1,
                          }}
                        >
                          <Grid>
                            <IconFile stroke={1.5} />
                          </Grid>
                          <strong>{item.description}</strong>
                        </MainCard>
                        <span>{item.total}</span>
                      </BodyBottom>
                    ))}
                  </BodyBottomContainer>
                ) : null}
              </BottomContainer>
            ) : information ? (
              <SearchEmpty
                textMessage="Nenhum dado a ser exibido"
                typeIcon="file"
                marginTop="40px"
              />
            ) : null}
          </Content>
        ) : (
          <SearchEmpty
            textMessage="Sem permissão para seu perfil"
            typeIcon="avatar"
            marginTop="40px"
          />
        )}
      </Container>
      {loading && (
        <LoadingContainer>
          <LoadingComponent loading={loading} />
        </LoadingContainer>
      )}
    </Fragment>
  );
};
