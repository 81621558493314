/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";

import { DropdownGlobal } from "../../components/DropdownGlobal";
import { FORMS_ADMIN } from "../../utils/entities/permission";
import { usePermissions } from "../../hooks/permission";
import {
  Container,
  ContainerBody,
} from "../../pages/app/StyleCSS/bodyCreateEdit";

import { ContainerInputsDate, ContainerWithButton } from "./style";
import { AddButton } from "@kentarepo/kcf-assets";

export type IProcedureType = {
  itemData?: any;
  setSelected: (item: any) => unknown;
  selected: any;
  attendantList: Array<any>;
  modalOpenNewParticipant: () => unknown;
  getAttedantList: () => unknown;
  fieldError: boolean;
};

export const ProcedureAttendant: React.FC<IProcedureType> = ({
  setSelected,
  modalOpenNewParticipant,
  attendantList,
  fieldError,
  getAttedantList,
}): JSX.Element => {
  const [closeDropDown, setCloseDropDown] = useState(false);
  const [createdParticipantName, setCreatedParticipantName] = useState("");

  const { create } = usePermissions(FORMS_ADMIN.ATTENDANT);

  useEffect(() => {
    if (closeDropDown && createdParticipantName) {
      setSelected(createdParticipantName);
    }
  }, [
    closeDropDown,
    createdParticipantName,
    setSelected,
    setCreatedParticipantName,
  ]);

  useEffect(() => {
    (async () => {
      await getAttedantList();
    })();
  }, []);

  return (
    <Container>
      <ContainerBody>
        <ContainerWithButton>
          <ContainerInputsDate hasPermission={create}>
            <DropdownGlobal
              error={fieldError}
              setSelected={setSelected}
              options={attendantList as any}
              labelName="Participante"
              onCloseDropDown={closeDropDown}
              required={true}
              width="325px"
            />
          </ContainerInputsDate>

          <AddButton
            onClick={() => {
              setCloseDropDown(!closeDropDown);
              modalOpenNewParticipant();
            }}
          />
        </ContainerWithButton>
      </ContainerBody>
    </Container>
  );
};
