import styled, { css } from "styled-components";
import { stylesGlobalDefault } from "../../../global/globalStyle";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ecf0f3;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${stylesGlobalDefault.blackMenu1};
  width: 100%;
  min-height: 80px;

  @media (max-width: 1366px) and (max-height: 768px) {
    width: 100%;
    min-height: 20px;
    //olhei
    overflow: hidden;
  }
`;

export const Title = styled.div`
  width: 400px;
  font-family: ${stylesGlobalDefault.fontFamily};
  font-weight: 700;
  font-size: 18px;
  color: ${stylesGlobalDefault.white};

  @media (max-width: 1366px) and (max-height: 768px) {
    font-size: 12px;
    overflow: hidden;
  }
`;

export const Subtitle = styled.div`
  width: 400px;
  font-family: ${stylesGlobalDefault.fontFamily};
  font-weight: 300;
  font-size: 14px;
  padding-left: 20px;
  padding-top: 4px;
  color: #ccc;
`;

export const ContainerDescription = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
`;

export const FooterContainer = styled.div`
  width: 100%;
  min-height: 80px;
  background: ${stylesGlobalDefault.blackMenu1};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;

  @media (max-width: 1366px) and (max-height: 768px) {   
    min-height: 60px;
    overflow: hidden;
  }
`;

export const BodyContainer = styled.div`
  width: 100%;
  height: 76vh;

  @media (max-width: 1366px) and (max-height: 768px) {
    width: 177.3vh;
    height: 82vh;
    overflow: hidden;
  }

`;

export type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void; // to handle onClick functions
  children?: React.ReactNode; // make the component able to receive children elements
  color?: "primary" | "secondary" | "third" | "forth" | "fifth" | "disabled"; // two styling options
  disabled?: boolean; // make the button disabled or not
  styles?: React.CSSProperties;
};

const COLOR: any = {
  primary: css`
    color: ${stylesGlobalDefault.white};
    background: ${stylesGlobalDefault.orange};
  `,
  secondary: css`
    color: ${stylesGlobalDefault.white};
    background: ${stylesGlobalDefault.blackMenu1};
  `,
  third: css`
    color: ${stylesGlobalDefault.blue};
    background: ${stylesGlobalDefault.white};
    border: 2px solid;
  `,
  forth: css`
    color: ${stylesGlobalDefault.white};
    background: ${stylesGlobalDefault.blue};
    border: none;
  `,
  fifth: css`
    color: ${stylesGlobalDefault.white};
    background: ${stylesGlobalDefault.blackMenu1};
    border: 2px solid ${stylesGlobalDefault.blue};
  `,
  disabled: css`
    color: ${stylesGlobalDefault.gray2};
    background: ${stylesGlobalDefault.blackMenu1};
    border: 2px solid ${stylesGlobalDefault.gray2};
  `,
};

const DISABLED = css`
  cursor: not-allowed;
  background: ${stylesGlobalDefault.blackMenu1};
  color: #ccc;
  border: 2px solid #ccc;
  pointer-events: none;
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 510px;
`;

export const ButtonFinish = styled.div<ButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px 10px 10px;
  cursor: pointer;
  border: none;
  border-radius: 50px;
  outline: none;
  transition: all 0.2s;
  min-width: 188px;
  font-family:${stylesGlobalDefault.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17.5px;

  ${(props: any) => props.color && COLOR[props.color]}
  ${(props: any) => props.disabled && DISABLED}
`;
