import React, { useEffect, useState } from "react";
import { ContainerList, ListItems, ListPagination } from "./styles";
import ReactPaginate from "react-paginate";
import backArrow from "../../../assets/svg/bx-arrow-back.svg"
import { SearchEmpty } from "@kentarepo/kcf-assets";

export interface IReactPaginate {
  list: React.ReactNode | React.ReactNode[];
  title: string;
  height?: string;
  onChangePage: (page: number) => void;
}

export const ReactPaginateKenta = ({ list, title, height, onChangePage }: IReactPaginate) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [valeuRegisterPerPage, setValeuRegisterPerPage] = useState<number>(7);
  const [previousLabel, setPreviousLabel] = useState<React.ReactNode>(<Arrow rotated={false} />);
  const [nextLabel, setNextLabel] = useState<React.ReactNode>(<Arrow rotated={true} />);

  const offset = currentPage * valeuRegisterPerPage;
  let currentPageData: React.ReactNode[] = [];
  if (Array.isArray(list)) {
    currentPageData = list.slice(offset, offset + valeuRegisterPerPage);
  } else if (typeof list === 'string') {
    //
  }
  const pageCount: number = Math.ceil(
    (Array.isArray(list) ? list : []).length / valeuRegisterPerPage
  );
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    const paginationLocal = localStorage.getItem("PaginationList");
    paginationLocal !== null
      ? setValeuRegisterPerPage(parseInt(paginationLocal))
      : setValeuRegisterPerPage(7);

    if (pageCount === 1) {
      setPreviousLabel("");
      setNextLabel("");
    }
  }, []);

  useEffect(() => {
    if (pageCount === 0) {
      setPreviousLabel("");
      setNextLabel("");
    } else {
      if (pageCount > 1) {
        setPreviousLabel("");
        setNextLabel(<Arrow rotated={true} />);
      }
    }
  }, [pageCount]);

  function handlePageClick(selectedPage: number) {
    setCurrentPage(selectedPage);
    formartPreviousLabel(selectedPage);
    formartNextLabel(selectedPage);
    onChangePage(selectedPage);
  }

  function formartPreviousLabel(selectedPage: number) {
    if (selectedPage === 0) {
      setPreviousLabel("");
    } else {
      setPreviousLabel(<Arrow rotated={false} />);
    }
  }

  function formartNextLabel(selectedPage: number) {
    const lastPage = selectedPage + 1;
    if (lastPage === pageCount) {
      setNextLabel("");
    } else {
      setNextLabel(<Arrow rotated={true} />);
    }
  }

 [list, currentPage, onChangePage, valeuRegisterPerPage];

  useEffect(() => {
    const pageCount = Math.ceil(
      (Array.isArray(list) ? list.length : 0) / valeuRegisterPerPage
    );
  

    if (currentPage >= pageCount - 1 && pageCount > 0) {
      const lastPage = pageCount - 1;
      setCurrentPage(lastPage); 
      onChangePage(lastPage);
    }
  }, [list, currentPage, onChangePage, valeuRegisterPerPage]);
  

  return (
    <>
      <ContainerList height={height}>
        {Array.isArray(list) && list.length > 0 ? (
          <div>
            <ListItems>
              <div data-cy="menuDivList" className="menudivList">{currentPageData}</div>
            </ListItems>
          </div>
        ) : (
          <SearchEmpty textMessage={title} typeIcon={"file"} />
        )}
      </ContainerList>
      <div>
        {pageCount > 1 && (
          <ListPagination>
            <ReactPaginate
              previousLabel={previousLabel}
              nextLabel={nextLabel}
              pageCount={pageCount > 1 ? pageCount : 0}
              onPageChange={(e: any) => handlePageClick(e.selected)}
              containerClassName={"pagination"}
              previousLinkClassName={
                previousLabel === "<"
                  ? "pagination__link"
                  : "previusAndNextDisableCursor"
              }
              nextLinkClassName={
                nextLabel === ">"
                  ? "pagination__link"
                  : "previusAndNextDisableCursor"
              }
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
            />
          </ListPagination>
        )}
      </div>
    </>
  );
};

interface ArrowProps {
  rotated: boolean;
}

export const Arrow: React.FC<ArrowProps> = ({ rotated }) => {
  return (
    <>
      {rotated ? (
        <img
          style={{
            transform: 'rotate(180deg)'
          }}
          src={backArrow}
          alt=""
        />
      ) : (
        <img
          src={backArrow}
          alt=""
        />
      )}
    </>
  );
};
