/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import close from "../../assets/close.svg";
import {
  Container,
  TopContainer,
  Title,
  Button,
  ProcessingVideoContainer,
} from "./styles";
import { IItensDetails, IRightAudienceComponent } from "./interface";

import FileIcon from "../../assets/svg/FileIcon.svg";
import EditIcon from "../../assets/svg/EditIcon.svg";
import EyeIcon from "../../assets/svg/EyeIcon.svg";
import PencilIcon from "../../assets/svg/PencilIcon.svg";
import CameraIcon from "../../assets/svg/CameraIcon.svg";
import { ItemDetailLine } from "../../components/ItemDetailLine";
import { useToast } from "../../hooks/toast";
import { useNavigate } from "react-router-dom";
import { getValueFromlocalStorage, insertTolocalStorage } from "../../utils/sessionStorageEncrypt";
import { LoadingContainer } from "../../pages/app/audiences/styles";
import { LoadingComponent } from "@kentarepo/kcf-assets";
import { useSelector } from "react-redux";

export const RightAudienceComponent: React.FC<IRightAudienceComponent> = ({
  onClose,
  title,
  baseBlob,
  record,
  fullViewNavigateFunction,
  disableFullView,
  hearingService,
  container
}) => {
  const notShowProcess = [10, 11, 14, 15]

  const [loading, setLoading] = useState(false);
  const { user: userRedux } = useSelector((e: any) => e.user);
  const { addToast } = useToast();
  const navigate = useNavigate();

  const canRejoinHearingSession = () => {
    return record.status === 12 && record?.attendantId === userRedux?.id
  };

  const rejoinHearingSession = async () => {
    const token = getValueFromlocalStorage("@Kenta:access_Token");
    if (token && record) {
      let responseRenewToken: any;

      setLoading(true);
      const response = await hearingService.getByHearingFileId(token, record?.id);

      if (response && response.data && response.data.videoRoom?.id) {
        responseRenewToken = await hearingService.renewTokens(
          token,
          response.data.videoRoom?.id
        );
      }

      if (response !== null && response.status === true) {
        if (
          responseRenewToken &&
          responseRenewToken.data &&
          responseRenewToken.data.deviceToken
        ) {
          document.cookie = `${responseRenewToken.data.deviceToken}`;
          document.cookie = `${responseRenewToken.data.authToken}`;
        } else {
          document.cookie = `${response.data.token}`;
          document.cookie = `${response.data.sessionToken}`;
        }

        setTimeout(() => {
          navigate("/recording/una", {
            state: {
              ...record,
              hearingId: record?.id,
              urlModerator: response.data.urlModerator,
              urlAttendee: response.data.urlAttendee,
              videoRoomId: response.data.videoRoom?.id,
              authToken: response.data.token,
              deviceToken: response.data.sessionToken,
              backToHearing: true,
            },
          });
          setLoading(false);
        }, 10000);
      } else {
        addToast({
          type: "error",
          title: "Não foi possivel fazer a gravação",
          description: "Verifique se a oitiva foi criada corretamente",
        });
        setLoading(false);
      }
    }
  };

  const fullViewNavigate = (page: number) => {
    fullViewNavigateFunction && fullViewNavigateFunction()
    insertTolocalStorage('choosePage', JSON.stringify(page));
  }

  const listLines: Array<IItensDetails> = [
    {
      icon: EyeIcon,
      label: "Visualização completa",
      disabled: disableFullView || notShowProcess.includes(record.status),
      onClick: () => fullViewNavigate(0),
      permission: true,
      hr: false,
    },
    {
      icon: FileIcon,
      label: "Transcrição",
      disabled: disableFullView || notShowProcess.includes(record.status),
      onClick: () => fullViewNavigate(1),
      permission: true,
      hr: false,
    },
    {
      icon: EditIcon,
      label: "Marcações",
      disabled: disableFullView || notShowProcess.includes(record.status),
      onClick: () => fullViewNavigate(0),
      permission: true,
      hr: true,
    },
    {
      icon: CameraIcon,
      label: "Reentrar na gravação",
      disabled: !canRejoinHearingSession() || notShowProcess.includes(record.status),
      onClick: () => {
        if (!canRejoinHearingSession()) {
          addToast({
            type: "error",
            title: "Ação não executada",
            description:
              "Só é possivel gravar a oitiva com status igual a aguardando",
          });
        } else {
          rejoinHearingSession();
        }
      },
      permission: canRejoinHearingSession(),
      hr: false,
    },
    {
      icon: PencilIcon,
      label: "Editar informações",
      disabled: true,
      onClick: () => { },
      permission: true,
      hr: false,
    },
  ];

  return (
    <>
      <Container>
        <TopContainer>
          <Title>{title}</Title>
          <Button onClick={onClose}>
            <img src={close} alt="" />
          </Button>
        </TopContainer>

        {record.status === 13 ? (
          <video
            width="100%"
            height="100%"
            controls
            controlsList="nodownload"
            onContextMenu={(event) => event.preventDefault()}
          >
            <source
              src={`${baseBlob}/${container}/${record.filename.replace(
                "\\",
                "/"
              )}`}
              type="video/mp4"
            />
            Desculpe, seu navegador não tem suporte o player de vídeo.
          </video>
        ) : (!notShowProcess.includes(record.status)) && (
          <ProcessingVideoContainer>
            <strong>Processando...</strong>
          </ProcessingVideoContainer>
        )}
        {listLines.map((line) => (
          <ItemDetailLine
            disabled={line.disabled}
            onClick={line.onClick as any}
            icon={<img src={line.icon} alt={line.label} />}
            label={line.label}
            hr={line.hr}
            permissions={line.permission}
          />
        ))}
      </Container>
      {loading && (
        <LoadingContainer>
          <LoadingComponent loading={loading} />
        </LoadingContainer>
      )}
    </>
  );
};
