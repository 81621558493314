import styled from "styled-components";

export const Container = styled.div`
    position: absolute;
    top: 60px;
    right: 0;
    min-width: 300px;
    max-width: 300px;
    background: #FFF;
    height: calc(100vh - 116px);
    padding: 28px 24px;


    video {
        height: 172px;
        width: 100%;
    }
`

export const TopContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
`

export const Title = styled.strong`
    font-family: "Inter", 'Courier New', Courier, monospace;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #000;
`

export const Button = styled.button`
    width: 14px;
    height: 14px;
    background: none;
    border: none;
    cursor: pointer;
`

export const ProcessingVideoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 160px;
  width: 100%;
  background: #fff;
  border: 2px solid #000;
  margin-bottom: 24px;

  strong {
    font-size: 14px;
    font-family: "Inter";
    font-weight: 700;
    color: #000;
  }
`;