/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";

import {
  Container,
  ContainerProgressBar,
  FileTitle,
  ConcludedIcon,
} from "./styles";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { stylesGlobalDefault } from "../../global/globalStyle";
import { IUpload } from "../../store/modules/attachment/interface";
import concluded from "../../assets/upload_completed.svg";
import api from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import {
  addUploadedFile,
  setAttachmentList,
} from "../../store/modules/attachment/actions";
import { useToast } from "../../hooks/toast";
import { hearingFileService } from "@kentarepo/kcf-services";
import UploadFileInBlock from "../../services/uploadFileInBlock";

interface IUploadFileItem {
  item: IUpload;
  isMain: boolean;
}

const UploadFileItem: React.FC<IUploadFileItem> = ({ item, isMain }) => {
  const [percentage, setPercentage] = useState(0);
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { uploadedList } = useSelector((state: any) => state.attachment);
  const hearingFileServiceApi = hearingFileService(api);

  useEffect(() => {
    (async () => {
      try {
        let canAdd = true;

        uploadedList.forEach((element: IUpload) => {
          if (element.payload.filename === item.payload.filename) {
            canAdd = false;
          }
        });
        const response = await hearingFileServiceApi.add({
          hearingId: item.hearingId,
          ...(item.payload as any),
        });
        if (canAdd) {
          response
        }
        const fileToUpload = item.file.get("content");
        if (!fileToUpload) {
          throw new Error("File is missing from FormData");
        }

        await UploadFileInBlock({ 
          file: fileToUpload, endpoint: 'UploadLargeFile', 
          hearingFileId: response?.data?.data, 
          isMain: isMain, 
          onProgress: setPercentage })          
          .then((uploadResponse) => {
            if (uploadResponse?.ok) {
              setPercentage(100)
            } else {
              throw new Error(uploadResponse.message);
            }
          })
          .catch((error) => {
            addToast({
              type: "error",
              title: "Erro no upload",
              description: `Ocorreu um erro: ${error.message}`,
            });
          });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (percentage === 100) {
        const { data, status } =
          await hearingFileServiceApi.getAllDownloadsByHearingId(
            parseInt(item.hearingId)
          );
        const list: any = [];
        if (status === 200) {
          data.data.forEach((element: any) => {
            if (element.type !== 1) {
              list.push(element);
            }
          });
          dispatch(setAttachmentList(list));
          dispatch(addUploadedFile(item));

          addToast({
            type: "success",
            title: "Aviso",
            description: "Seu arquivo foi enviado com sucesso",
          });
        }
      }
    })();
  }, [percentage]);

  return (
    <Container>
      <FileTitle>{item.payload.title}</FileTitle>
      <ContainerProgressBar>
        {percentage >= 100 ? (
          <ConcludedIcon src={concluded} alt="" />
        ) : (
          <CircularProgressbar
            value={percentage}
            strokeWidth={15}
            background
            backgroundPadding={10}
            styles={buildStyles({
              backgroundColor: stylesGlobalDefault.blue,
              textColor: stylesGlobalDefault.white,
              pathColor: stylesGlobalDefault.white,
              trailColor: "transparent",
              strokeLinecap: "butt",
            })}
          />
        )}
      </ContainerProgressBar>
    </Container>
  );
};

export default UploadFileItem;